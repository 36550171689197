import { integrates, supports } from "../assets";

export const sectionData = [
  {
    diff: {
      id: 1,
      name: "diff",
      title: "No more flacky Test",
      desc: "Script complex interactions and assertions using plain-text instructions",
    },
    stats: {
      id: 2,
      name: "stats",
      title: "Write E2E tests that can adapt to modifications",
      desc: "Author complex interactions and queries 10x faster using plan-text instructions",
    },
    whyUse: {
      id: 3,
      name: "why use",
      title: "Why use Autoflow?",
      desc: "Optimize Playwright's capabilities intelligently, avoiding the pitfalls commonly encountered in E2E testing.",
    },
    pricing: {
      id: 4,
      name: "pricing",
      title: "Pricing",
      desc: "We provide a generous free tier and straight-forward pricing",
    },
    features: {
      id: 5,
      name: "features",
      title: "AutoFlow fits right into your development lifecycle!",
      desc: "",
    },
  },
];

export const whyUseData = [
  {
    conventionalApproach: {
      heading: "The Traditional Approach",
      data: [
        createItem(
          "Old school",
          "Selectors stick like glue! A small change and you need to update the selectors in your tests.",
          "text-red-400/50"
        ),
        createItem(
          "Snail-paced tests",
          "Every click to automate is a code marathon! E2E test implementations are known to take a frustrating amount of time",
          "text-red-400/50"
        ),
        createItem(
          "Chaos alert",
          "The more complex the app, the more frequent the test breakage and false-positives",
          "text-red-400/50"
        ),
        createItem(
          "Wait and test",
          "E2E testing enters only after the functionality to test is feature-complete!",
          "text-red-400/50"
        ),
      ],
    },
    autoflowApproach: {
      heading: "Testing with Autoflow",
      data: [
        createItem(
          "Bye-bye selectors",
          "AutoFlow doesn't use selectors! Our AI determines the action to take by evaluating what's on the page",
          "text-primary-color"
        ),
        createItem(
          "Talk to test",
          "Text talks faster than code walks! You can now write your tests in natural language in 1/10th the time",
          "text-primary-color"
        ),
        createItem(
          "Dream it, Test it",
          "If you can say it, AutoFlow can automate and test it!",
          "text-primary-color"
        ),
        createItem(
          "Break Free",
          "AutoFlow loves TDD, no app strings attached! Finally, a robust way to implement the TDD approach in your application",
          "text-primary-color"
        ),
      ],
    },
  },
];

function createItem(heading, description, textColor) {
  return (
    <article className="text-wrap">
      <div className={`font-bold font-primary leading-6 ${textColor}`}>
        {heading}
      </div>
      <div className="font-light font-secondary">{description}</div>
    </article>
  );
}

export const statsListData = [
  {
    id: 1,
    content: "Verify the number of labels in a repo",
  },
  {
    id: 2,
    content: "Recent Stories Count",
  },
  {
    id: 3,
    content: "Shopping and Checkout",
  },
  {
    id: 4,
    content: "Add/Remove Elements",
  },
  {
    id: 5,
    content: "Number of Checked Checkboxes",
  },
  {
    id: 6,
    content: "Elements Rendered After Action",
  },
  {
    id: 7,
    content: "Get the text in the iframe",
  },
  {
    id: 8,
    content: "JQuery UI Menu Navigation",
  },
];

export const codeData = [
  {
    selected1: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "test.describe('GitHub', () => {",
        "  test('verify the number of labels in a repo', async ({ page }) => {",
        "    await page.goto('https://github.com/AutoFlowLabs/autoflow');",
        "    await autoflow(`Click on the 'Issues' tabs`, { page, test }, {flowType: 'action'});",
        "",
        "    await page.waitForURL('https://github.com/AutoFlowLabs/autoflow/issues');",
        "    await autoflow('Click on Labels', { page, test }, {flowType: 'action'});",
        "",
        "    await page.waitForURL('https://github.com/AutoFlowLabs/autoflow/labels');",
        "    const numLabels = (await autoflow('How many labels are listed?',{ page, test }, {flowType: 'query'})) as string;",
        "",
        "    expect(parseInt(numLabels)).toEqual(9);",
        "  })",
        "})",
      ],
    },
  },
  {
    selected2: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "test.describe('Lobster', () => {",

        "  test('Lobster Recent Stories Count', async ({ page }) => {",
        "    await page.goto('https://lobste.rs/');",
        "    await autoflow(`click on the 'recent' tab`, { page, test }, {flowType: 'action'});",
        "",
        "    await page.waitForURL('https://lobste.rs/recent');",
        "    const numStories = (await autoflow('How many stories are listed?',{ page, test }, {flowType: 'query'})) as string;",
        "",
        "    expect(parseInt(numStories)).toEqual(10);",
        "",
        "  })",
        "})",
      ],
    },
  },
  {
    selected3: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "const firstNameForSauce = 'jon';",
        "const lastNameForSauce = 'doe';",
        "const zipForSauce = '335001'",
        "",
        "test.describe('SauceDemo', () => {",
        "  test('SauceDemo Shopping and Checkout', async ({ page }) => {",
        "    await page.goto('https://www.saucedemo.com/');",
        `    await autoflow('Fill the "username" with a standard_user', { page, test }, {flowType: 'action'})`,
        "",
        `    await autoflow('Fill the "password" with secret_sauce',{ page, test }, {flowType: 'action'});`,
        `    await autoflow('click on the "login button"', { page, test }, {flowType: 'action'});`,
        "",
        "    await page.waitForURL('https://www.saucedemo.com/inventory.html');",

        `    await autoflow('click "add to cart" button of item with name "Sauce Labs Backpack"',{ page, test }, {flowType: 'action'});`,
        "",
        `    await autoflow('click "add to cart" button of item with name "Sauce Labs Bike Light"',{ page, test }, {flowType: 'action'});`,
        "    await autoflow(`Click the 'shopping cart icon'`, { page, test }, {flowType: 'action'});",
        "",
        `    await page.waitForURL('https://www.saucedemo.com/cart.html');`,
        `    await autoflow('click the "checkout" button', { page, test }, {flowType: 'action'});`,
        "",
        "    await page.waitForURL('https://www.saucedemo.com/checkout-step-one.html');",
        `    await autoflow(\`fill the 'first name' with \${firstNameForSauce}\`,{ page, test }, {flowType: 'action'});`,
        "",
        `    await autoflow(\`fill the 'last name' with \${lastNameForSauce}\`,{ page, test }, {flowType: 'action'});`,
        `    await autoflow(\`fill the 'zip/postal code' with \${zipForSauce}\`,{ page, test }, {flowType: 'action'});`,
        "",
        "    await autoflow(`click the 'continue' button`, { page, test }, {flowType: 'action'});",
        "    await page.waitForURL('https://www.saucedemo.com/checkout-step-two.html');",
        "",
        "  })",
        "})",
      ],
    },
  },
  {
    selected4: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "test.describe('HerokuApp', () => {",
        "  test('Add/Remove Elements', async ({ page }) => {",
        "    await page.goto('https://the-internet.herokuapp.com/add_remove_elements/');",
        `    await autoflow(\`Click on the 'add element' button\`,{ page, test }, {flowType: 'action'});`,
        "",
        `    await autoflow(\`Click on the 'delete' button\`, { page, test }, {flowType: 'action'});',
        '})`,
      ],
    },
  },
  {
    selected5: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "test.describe('HerokuApp', () => {",
        "  test('Number of Checked Checkboxes', async ({ page }) => {",
        "    await page.goto('https://the-internet.herokuapp.com/checkboxes');",
        `    await autoflow(\`Click on the 'checkbox 1'\`, { page, test }, {flowType: 'action'});`,
        "",
        `    await autoflow(\`Click on the 'checkbox 2'\`, { page, test }, {flowType: 'action'});`,
        "    await page.waitForTimeout(2000);",
        "",
        `    const noOfCheckedCheckboxes = await autoflow('how many checkboxes are checked?',{ page, test }, {flowType: 'query'});`,
        "",
        "    expect(parseInt(noOfCheckedCheckboxes)).toEqual(1);",
        "",
        "  })",
        "})",
      ],
    },
  },
  {
    selected6: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "test.describe('HerokuApp', () => {",
        "  test('Elements Rendered After Action Test', async ({ page }) => {",
        "    await page.goto('https://the-internet.herokuapp.com/dynamic_loading/2');",
        "    await autoflow(`Click on the 'start' button`, { page, test }, {flowType: 'action'});",
        "",
        "    await page.waitForTimeout(5000);",
        `    const linkText = await autoflow(\`Is there a hello world text written on this page?\`,{ page, test }, {flowType: 'assert'});`,
        "",
        "    expect(linkText).toBe(true);",
        "  })",
        "})",
      ],
    },
  },
  {
    selected7: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "test.describe('HerokuApp', () => {",
        "  test('Get the text in the iframe', async ({ page }) => {",
        "    await page.goto('https://the-internet.herokuapp.com/iframe');",
        "",
        `    const text = await autoflow(\`get the text in the iframe\`,{ page, test }, {flowType: 'query'});`,
        "",
        "    console.log(text);",
        "",
        "})",
      ],
    },
  },
  {
    selected8: {
      code: [
        "import { test, expect } from '@playwright/test'",
        "import { autoflow } from '@autoflowlabs/playwright'",
        "",
        "test.describe('HerokuApp', () => {",
        "  test('JQuery UI Menu Navigation', async ({ page }) => {",
        "    await page.goto('https://the-internet.herokuapp.com/jqueryui/menu');",
        "",
        `   await autoflow(\`click on 'enabled' link\`, { page, test }, {flowType: 'action'});`,
        `   await autoflow(\`click on back to 'jquery ui' link\`, { page, test }, {flowType: 'action'});`,
        "",
        "})",
      ],
    },
  },
  {
    heroDiffCode1: {
      code: [
        'test("Validate user is able to rename the file", async function () {',
        '    await clickOnSidebar($(`[title="${collName}"] .is-active *[class*="sidebar"]`););',
        "    await expectFileExistInSidebar();",
        "    await clickFile($(",
        '        `//div[contains(@class, "collection-sidebar-list-item__head__head") and',
        '        @title="${title}" and not(.//div[contains(@class, "has-fork-label")]//span)]',
        '        //div[@className"collection-sidebar-list-item__head__name"]`',
        "    );",
        "    await expectInputBox(",
        '        fileTitle("test_file"),',
        "        fileName",
        "    );",
        "    await safeType($(",
        '        `[data-testid="nr-import-text-input-password"]`,',
        '    );, "NewFileName");',
        "    ...",
        "    Around 100 more lines",
        "});",
        "fileTitle(title) {",
        "    return $(",
        '        `//div[contains(@class, "collection-sidebar-list-item__head__head") and @title="${title}"',
        '          and not(.//div[contains(@class, "has-fork-label")]//span)]',
        '         //div[@className"collection-sidebar-list-item__head__name"]`',
        "    );",
        "}",
        "",
        "fileStarredByName(fileName) {",
        '    return $(`[title="${collName}"] .is-active *[class*="favorite-button"]`);',
        "}",
        "",
        "entityByNameLocator(name) {",
        '    return $(`[title="${name}"]`);',
        "}",
        "",
        "apmKeyInput () {",
        "    return $(",
        '        `[data-testid="nr-import-text-input-password"]`,',
        "    );",
        "}",
        "",
        "async clickOnSidebar(tab) {",
        "    const activeTab = await this.isActive(tab);",
        "",
        "    if (!(await activeTab.isDisplayed())) {",
        "        await safeClick(await this.sideBarItem(tab));",
        "",
        "        // check if sidebar collapsed",
        "        const isSidebarCollapsed = await (await this.sideBarCollapsedElement).isExisting();",
        "        if (isSidebarCollapsed){",
        "            await safeClick(await this.sideBarItem(tab));",
        "        }",
        "        await activeTab.waitForDisplayed();",
        "}",
        "",
        "async clickFile(collectionName) {",
        "    await safeDisplayCheck(this.fileTitle(collectionName));",
        "    await safeClick(this.fileTitle(collectionName));",
        "}",
        "",
        "async expectInputBox(inputBoxSelector, fileName) {",
        "    await (await this.inputBoxSelector).waitForDisplayed({",
        '      timeoutMsg: "Input box is not displayed",',
        "    });",
        "    await safeClick(this.fileTitle(fileName));",
        "    const isRequestDisplayed = await safeDisplayCheck(",
        "      this.entityByNameLocator(fileName)",
        "    );",
        "    expectChai(isRequestDisplayed, `Given request not displayed`).to.be.true;",
        "  }",
        "",
        "async enterNewRelicKey (key) {",
        "    ",
        "}",
        "",
        "fileStarredByName(fileName) {",
        '    return $(`[title="${collName}"] .is-active *[class*="favorite-button"]`);',
        "}",
        "",
        "allCollectionsSidebar(fileName) {",
        '    return $(`[title="sidebar"] .is-active *[class*="top"]`);',
        "}",
        "async expectFileExistInSidebar(timeoutLength = Time.FIVE_SECONDS) {",
        "    if (timeoutLength) {",
        "      await waitUntil(",
        "        async () => (await this.allCollectionsSidebar).length > 0,",
        "        {",
        "          timeoutMsg: `Collections have not appeared in sidebar!`,",
        "          timeout: timeoutLength,",
        "        }",
        "      );",
        "       {/* check for sidebar loading state to pass assert and log a maximum time to",
        "          the performance dashboard  */}",
        "      await this.loadingState.isDisplayed();",
        "      return;",
        "    }",
        "    // wait until side bar shows at-least one collection info",
        "    await waitUntil(async () => (await this.allCollectionsSidebar).length > 0, {",
        "      timeoutMsg: `Collections have not appeared in sidebar!`,",
        "    });",
        "}",
        "",
      ],
    },
  },
  {
    heroDiffCode2: {
      code: [
        "",
        'test.describe("Validate user is able to rename the file", () => {',
        '    test("check the attributions", async ({ page }) => {',
        "        testArgs = { page, test };",
        '        await autoflow("Click on sidebar", testArgs, {flowType: "action"})',
        '        await autoflow("Validate a file exists in the sidebar", testArgs, {flowType: "assert"})',
        '        await autoflow("Click on file", testArgs, {flowType: "action"})',
        '        await autoflow("Enter a random filename", testArgs, {flowType: "action"})',
        '        await autoflow("Press enter", testArgs, {flowType: "action"})',
        '        await autoflow("Validate filename is changed", testArgs, {flowType: "action"})',
        "    });",
        "});",
        "",
      ],
    },
  },
];

export const pricingData = [
  {
    free: {
      id: "1",
      title: "Free Plan",
      description: "For anyone to try intelligent UI Testing",
      price: "0",
      tenure: "month",
      buttonName: "Get Started Free",
      features: [
        <span key="1">
          1,000{" "}
          <span className="text-primary-color font-semibold">autoflow()</span>{" "}
          invocations per month
        </span>,
        "Single user",
      ],
    },
  },
];

export const featuresData = [
  {
    integrates: {
      id: "1",
      title: "Integrates into your CI/CD workflow",
      description:
        "AutoFlow library seamlessly plugs into your existing CI/CD pipelines",
      img: integrates,
      alt: "integrates",
    },
    supports: {
      id: "2",
      title: "Supports UI Mode",
      description:
        "AutoFlow supports playwright's UI mode, allowing you to effortlessly monitor and manage your tests through its intuitive UI",
      img: supports,
      alt: "supports",
    },
  },
];
