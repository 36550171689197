import React, { useState } from "react";
import SparkleBackground from "../../Animations/SparkleBackground";
import AppSectionHeader from "../reUsableComponents/AppSectionHeader";
import { useLocation } from "react-router-dom";

const SubmitForm = () => {
  const location = useLocation();
  const data = location.state?.data;
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="relative overflow-hidden">
      <div className="relative mx-auto max-w-5xl px-4 sm:px-6">
        <div className="pb-24 pt-32">
          <SparkleBackground>
            <AppSectionHeader
              title={data.title}
              mainTitle="Contact us"
              description={data.description}
            />
          </SparkleBackground>
          {isLoading && (
            <div className="flex items-center justify-center">
              <span className="loading loading-infinity text-warning w-20 opacity-50"></span>
            </div>
          )}
          <iframe
            src={data.src}
            className={`h-[900px] sm:h-[800px] w-full rounded-md ${
              isLoading ? "hidden" : ""
            }`}
            title="Get Early Access to AutoFlow - DownloadPage"
            onLoad={handleLoad}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default SubmitForm;
