import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { GiElectric } from "react-icons/gi";
import { GoPeople } from "react-icons/go";
import { ImPower } from "react-icons/im";
import { IoIosHeartEmpty } from "react-icons/io";
import * as styles from "./AppDeveloperToolsStyles";
import "./dev.css";

const AppDeveloperTools = () => {
  const [tools, setTools] = useState("Fast");

  const icons = {
    Fast: <GiElectric className={styles.icon} />,
    "Open-Source": <FaRegHeart className={styles.icon} />,
    Teams: <GoPeople className={styles.icon} />,
  };

  const rotateVariants = {
    hidden: { opacity: 0, rotate: 260 },
    visible: { opacity: 1, rotate: 360 },
  };

  const buttons = [
    { type: "Fast", icon: <ImPower />, text: "Built to be Fast" },
    {
      type: "Open-Source",
      icon: <IoIosHeartEmpty />,
      text: "Cut testing costs and time",
    },
    { type: "Teams", icon: <GoPeople />, text: "Designed for Teams" },
  ];
  return (
    <section className="py-10">
      <div className={styles.container}>
        <div className="pt-32 pb-24 ">
          <div className={styles.flexContainer}>
            <div className={styles.textContainer}>
              <span className={styles.gradientSpan}>
                QA-first Software Testing
              </span>
              <h3 className={styles.mainHeading}>
                No-Code Automated Testing Tool For Web Applications
              </h3>
              <p className="mb-8 font-display font-normal text-secondary-color-light/50 text-sm">
                AutoFlow makes it easy to create, execute and maintain your
                tests suites
              </p>
              <div className={styles.buttonContainer}>
                {buttons.map((button, index) => (
                  <button
                    key={index}
                    className={`${styles.button} ${
                      tools === button.type
                        ? "border-primary-color/50 text-primary-color/80 shadow shadow-primary-color/50"
                        : "border-secondary-color-light/10"
                    }`}
                    onClick={() => setTools(button.type)}
                  >
                    {button.icon}
                    {button.text}
                  </button>
                ))}
              </div>
            </div>
            <div className="md:w-5/12 lg:w-1/2">
              <div className="relative py-24">
                <div className="flex items-center justify-center">
                  <div className="relative flex items-center justify-center w-48 h-48">
                    <svg
                      className="absolute inset-0 pointer-events-none transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 will-change-transform blur-md"
                      width="480"
                      height="480"
                      viewBox="0 0 480 480"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          id="pulse-a"
                          x1="50%"
                          x2="50%"
                          y1="100%"
                          y2="0%"
                          data-v-452fae4b=""
                        >
                          <stop
                            offset="0%"
                            stopColor="#000"
                            data-v-452fae4b=""
                          ></stop>
                          <stop
                            offset="50%"
                            stopColor="#ddc319df"
                            data-v-452fae4b=""
                          ></stop>
                          <stop
                            offset="100%"
                            stopColor="#000"
                            data-v-452fae4b=""
                          ></stop>
                        </linearGradient>
                      </defs>
                      <g fillRule="evenodd" data-v-452fae4b="">
                        <path
                          className="pulse"
                          fill="url(#pulse-a)"
                          fillRule="evenodd"
                          d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                          data-v-452fae4b=""
                        ></path>
                        <path
                          className="pulse pulse-1"
                          fill="url(#pulse-a)"
                          fillRule="evenodd"
                          d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                          data-v-452fae4b=""
                        ></path>
                        <path
                          className="pulse pulse-2"
                          fill="url(#pulse-a)"
                          fillRule="evenodd"
                          d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                          data-v-452fae4b=""
                        ></path>
                      </g>
                    </svg>
                    <div
                      className="absolute inset-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none w-[500px] h-[500px] rounded-full  overflow-hidden"
                      style={{
                        maskImage: "radial-gradient(black, transparent 60%)",
                      }}
                    >
                      <div className="h-[200%] animate-endless-wave">
                        <div className={styles.waveLayer1}></div>
                        <div className={styles.waveLayer2}></div>
                        <div className={styles.waveLayer3}></div>
                        <div className={styles.waveLayer4}></div>
                      </div>
                    </div>

                    <div className="absolute">
                      {Object.keys(icons).map((key) => (
                        <motion.div
                          key={key}
                          style={{
                            display: tools === key ? "flex" : "none",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          animate={tools === key ? "visible" : "hidden"}
                          variants={rotateVariants}
                          transition={{ duration: 0.3 }}
                        >
                          <div className={styles.iconCircle}>{icons[key]}</div>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDeveloperTools;
