import React from "react";
import { useState } from "react";
import Code from "./Code";
import { SectionHeader } from "../SectionHeader";
import { sectionData, statsListData } from "../../data";
import { layout } from "../../styles/styles";

const Stats = () => {
  const [selectedItem, setSelectedItem] = useState(statsListData[0].id);

  return (
    <section className={`${layout.section} background-coming-soon`} id="examples">
      <div className="text-center p-6">
        <SectionHeader
          title={sectionData[0].stats.title}
          desc={sectionData[0].stats.desc}
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-2 sm:gap-0 text-primary-color/55">
        <div>
          <ul className="menu w-full md:w-96 p-0 py-4 rounded-l-md border-none ">
            {statsListData.map((item) => (
              <li
                key={item.id}
                className={`border-b border-gray-500 text-base font-secondary ${
                  selectedItem === item.id ? "bg-gray-700 md:scale-110 text-primary-color drop-shadow-2xl rounded-md border-none font-primary font-bold" : ""
                }`}
              >
                <a href={item.link} onClick={() => setSelectedItem(item.id)}>
                  {item.content}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:w-[700px] xl:w-[1000px] p-2 md:p-0 overflow-auto">
          <Code selectedItem={selectedItem} />
        </div>
      </div>
    </section>
  );
};

export default Stats;
