import React from 'react';

/**
 * ProgressBar Component
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {number} [props.value=0] - The current progress value (0-100).
 * @param {string} [props.customClass] - Custom class for styling the outer bar.
 * @param {boolean} [props.animated] - If true, adds smooth transition effects.
 */

function ProgressBar({ value = 0, customClass, animated = true }) {
  // Ensure the progress value stays between 0 and 100.
  const clampedValue = Math.min(100, Math.max(0, value));

  // Determine the color class based on the value.
  let colorClass = 'bg-surface-accent-green-overlay-primary'; // Default: Green
  if (clampedValue < 100) colorClass = 'bg-surface-accent-red-overlay-primary';

  // Outer bar class combining custom and bold styles.
  const outerClass = `${customClass || 'w-56 h-1 rounded-full overflow-hidden bg-alpha-200'}`;

  // Inner progress bar class with conditional animation.
  const innerClass = `h-full ${colorClass} ${animated ? 'transition-all duration-300 ease-in-out' : ''}`;

  return (
    <div
      className={outerClass}
      role="progressbar"
      aria-valuenow={clampedValue}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div
        className={innerClass}
        style={{ width: `${clampedValue}%` }}
      ></div>
    </div>
  );
}


export default ProgressBar;
