import React from 'react'

function AppWorks() {
    return (
        <div>
            <section className="container mx-auto py-16 px-4 pt-56 pb-24">
                <h2 className="text-center  text-3xl sm:text-4xl md:text-5xl text-transparent bg-clip-text max-w-max bg-gradient-to-r from-white via-white/80 to-white/30 mx-auto">How does AutoFlow Studio work?</h2>
                <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-center items-center text-white px-4 sm:px-8 md:px-0 pt-12 md:pt-16 gap-20">
                    <div className='w-full md:w-[60%] lg:w-[30%] md:[40%] sm:[50%]' >
                        <h3 className="text-yellow-500 text-lg font-bold mb-2">STEP #1</h3>
                        <h4 className="text-2xl font-semibold text-secondary-color-light/90  mb-4">Record your User Journey</h4>
                        <ul className=' list-disc list-outside'>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Utilize Autoflow's industry-leading test recorder for local test execution and immediate results.</li>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Efficiently manage and edit tests using AI</li>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Create reusable and randomized test step and set them as variable for future use.</li>
                        </ul>
                    </div>
                    <div className="flex justify-center">
                        <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/picture-step-1.png?updatedAt=1724335262617" alt="AutoFlow Studio Test Automation" className="max-w-sm h-auto w-full sm:w-auto object-contain"></img>
                    </div>
                </div>
            </section>
            <section className="container mx-auto py-16 px-4">
                <div className="flex flex-col-reverse sm:flex-row md:flex-row lg:flex-row justify-center items-center text-white px-4 md:px-0 pt-12 md:pt-16 gap-20">
                <div className="flex justify-center">
                    <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/scheduleOrRun.png?updatedAt=1724664158524" alt="AutoFlow Studio Test Automation" className="max-w-sm h-auto w-full sm:w-auto object-contain"></img>
                </div>
                    <div className='w-full md:w-[60%] lg:w-[30%] md:[40%] sm:[50%]' >
                        <h3 className="text-yellow-500 text-lg font-bold mb-2">STEP #2</h3>
                        <h4 className="text-2xl font-semibold mb-4 text-secondary-color-light/90 ">Run and Integrate</h4>
                        <ul className=' list-disc list-outside'>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Leverage Autoflow's cloud-based test execution for scheduled or CI/CD-triggered runs</li>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Seamlessly integrate tests using our CLI, API, or predefined schedules.</li>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Create efficient, continuous testing workflows for optimal efficiency.</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="container mx-auto py-16 px-4">
                <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-center items-center text-white px-4 md:px-0 pt-12 md:pt-16 gap-20">
                    <div className='w-full md:w-[60%] lg:w-[30%] md:[40%] sm:[50%]' >
                        <h3 className="text-yellow-500 text-lg font-bold mb-2">STEP #3</h3>
                        <h4 className="text-2xl font-semibold mb-4 text-secondary-color-light/90 ">Alerts and Report</h4>
                        <ul className=' list-disc list-outside'>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Receive real-time alerts via email, Slack, or other platforms through our integrations.</li>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Generate clear and sharable reports for efficient stakeholder communication.</li>
                        </ul>
                         </div>
                    <div className="flex justify-center">
                        <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/Integrations.png?updatedAt=1724664158490" alt="AutoFlow Studio Test Automation" className="max-w-sm h-auto w-full sm:w-auto object-contain"></img>
                    </div>
                </div>
            </section>
            <section className="container mx-auto py-16 px-4">
                <div className="flex flex-col-reverse sm:flex-row md:flex-row lg:flex-row justify-center items-center text-white px-4 md:px-0 pt-12 md:pt-16 gap-20">
                <div className="flex justify-center">
                    <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/invite-users.png?updatedAt=1724664158504" alt="AutoFlow Studio Test Automation" className="max-w-sm h-auto w-full sm:w-auto object-contain"></img>
                </div>
                    <div className='w-full md:w-[60%] lg:w-[30%] md:[40%] sm:[50%]' >
                        <h3 className="text-yellow-500 text-lg font-bold mb-2">STEP #4</h3>
                        <h4 className="text-2xl font-semibold mb-4 text-secondary-color-light/90 ">Collaborate and Optimize</h4>
                        <ul className=' list-disc list-outside'>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Invite unlimited team members to collaborate on tests.</li>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Easily re-record specific test steps without starting over.</li>
                            <li className="text-sm mb-4 text-secondary-color-light/50 ">Create and manage reusable test nodes for efficiency</li>
                        </ul>
                       </div>
                </div>
            </section>
        </div>
    )
}

export default AppWorks