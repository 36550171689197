import React from "react";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen w-screen bg-primary-color-dark text-white">
      <div className="text-center font-semibold p-4">
        <h2 className="text-white text-3xl md:text-6xl text-center mx-auto">
          404 - Page Not Found
        </h2>
        <p className="mt-6 text-base leading-7 text-gray-300">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        {/* <Link to={home}>
          <button className="btn btn-sm md:btn-md btn-warning text-black font-primary font-bold mt-6 md:text-base">
            Return to home page
          </button>
        </Link> */}
      </div>
    </div>
  );
};

export default NotFound;
