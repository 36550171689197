import React, { useEffect, useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Session from "supertokens-web-js/recipe/session";
import { postResendVerifyEmail } from "../../api/services/apiService";
import { appLogin, sdkLogin } from "../../routes/routes";
import { emailVerify } from "../../assets";

const EmailConfirmation = ({logFrom}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState(30);

  useEffect(() => {
    const sessionErase = async () => {
      await Session.signOut();
    };
    sessionErase();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchEmail = async () => {
      let accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
      if (accessTokenPayload?.user_info?.email) {
        setEmail(accessTokenPayload.user_info.email);
      }
    };
    fetchEmail();
  }, []);

  const handleLogOut = async () => {
    await Session.signOut();
    navigate(logFrom?appLogin:sdkLogin);
    window.location.reload();
  };

  const handleResend = async () => {
    setLoading(true);
    let payload = {
      email: email && email,
    };
    if (email) {
      postResendVerifyEmail(payload)
        .then((res) => {
          setLoading(false);
          toast.success(res.detail);
          setRemainingTime(30);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-black relative overflow-hidden">
      <div className="fixed top-5 right-5">
        <button
          className="btn btn-warning rounded-full btn-ghost"
          onClick={handleLogOut}
        >
          <ImCancelCircle className="text-3xl text-primary-color" />
        </button>
      </div>
      <div className="flex flex-col items-center">
        <div>
          <video
            autoPlay
            muted
            loop
            playsInline
            className="max-w-xs sm:max-w-md text-center"
          >
            <source src={emailVerify} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="text-white text-center">
          <h1 className="text-xl sm:text-4xl font-primary font-bold mb-4">
            Verify your email address
          </h1>
          <p className="w-[360px] sm:w-[500px] text-xs sm:text-base font-secondary font-light">
            An email has been sent to{" "}
            <strong className="text-primary-color">{email && email}</strong>.
            Please click on the link in the email to verify your email address.
          </p>
        </div>
        <div className="mt-20">
          {remainingTime > 0 && (
            <span className="countdown text-white font-primary font-semibold ml-1 flex items-center">
              <span style={{ "--value": 0 }}></span>: {remainingTime}
            </span>
          )}
          <div className="flex w-[360px] sm:w-[500px] flex-row justify-center">
            <div className="w-[500px]">
              <button
                className={`btn btn-sm md:btn-md w-full ${
                  loading && "btn-disabled"
                } font-secondary btn-warning text-sm sm:text-base ${
                  remainingTime > 0 &&
                  "cursor-not-allowed no-animation bg-primary-color/60 hover:bg-primary-color/60 border-none"
                }`}
                onClick={remainingTime < 0 ? handleResend : null}
              >
                {loading ? (
                  <span className="loading loading-bars loading-sm md:loading-lg text-warning"></span>
                ) : (
                  "Re-send"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
