import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
 
import { GoUnverified } from "react-icons/go";
import { profile3 } from "../../../api/services/apiService";
import { sdkCreateAccount, sdkVerifyEmail } from "../../../routes/routes";
import SideBar from "../sideBar";
import Header from "./Header";

const SdkDashboardLayout = ({ sessionExists, isEmailVerified,logFrom }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  useEffect(() => {
    profile3()
      .then((res) => {
        setUserData(res);
        if (
          !(res.ProfileData.name && res.ProfileData.name.length >= 0) ||
          !res.ProfileData.organisation
        ) {
          navigate(sdkCreateAccount);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="flex flex-row h-screen w-screen relative overflow-hidden">
      {!Boolean(isEmailVerified) && (
        <div className="banner absolute top-5 right-52 text-white z-[990] font-primary font-bold">
          <div role="alert" className="alert alert-warning">
            <GoUnverified className="text-xl font-bold" />
            <div>
              Your email is not verified.{" "}
              <a href={sdkVerifyEmail} className="underline">
                Click here
              </a>{" "}
              to verify your email
            </div>
          </div>
          {/* Your email is not verified.{" "}
          <Link href="#">
            Click here
          </Link>{" "}
          to verify your email. */}
        </div>
      )}
      <SideBar logFrom={logFrom} />
      <div className="flex-1 bg-black overflow-auto relative">
        <Header logFrom={logFrom} userData={userData && userData} />
        <Outlet />
      </div>
    </div>
  );
};

export default SdkDashboardLayout;
