import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import SuperTokens from "supertokens-auth-react";
import SessionReact, { getUserId } from "supertokens-auth-react/recipe/session";
import ThirdPartyEmailPassword from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-web-js/recipe/session";
import { getAxiosInstance } from "../../api/services/apiService";
import SdkLandingPage from "../../pages/SdkLandingPage";
import {
  sdkAccount,
  sdkChangePwd,
  sdkLogin,
  sdkVerifyEmail,
} from "../../routes/routes";

import ChangePassword from "../../auth/sdkLandingPage/ChangePassword";
import Invitation from "../../auth/sdkLandingPage/Invitation";
import AccountCreated from "../../auth/sdkLandingPage/AccountCreated";

import EmailConfirmation from "../../auth/sdkLandingPage/EmailConfirmation";
import ForgetPassword from "../../auth/sdkLandingPage/ForgetPassword";
import GithubAuth from "../../auth/sdkLandingPage/GithubAuth";
import GoogleAuth from "../../auth/sdkLandingPage/GoogleAuth";
import ResetPassword from "../../auth/sdkLandingPage/ResetPassword";
import Login from "../../auth/sdkLandingPage/login";
import Signup from "../../auth/sdkLandingPage/signup";
import AccountInformation from "../../components/SdkLandingPageComponents/accountInformation";
import Apikeys from "../../components/SdkLandingPageComponents/apiKeys";
import Billing from "../../components/SdkLandingPageComponents/billing";
import PrivacyPolicy from "../../components/SdkLandingPageComponents/landingpage/components/PrivacyPolicy";
import ReturnPolicy from "../../components/SdkLandingPageComponents/landingpage/components/ReturnPolicy";
import TermsOfService from "../../components/SdkLandingPageComponents/landingpage/components/TermsOfService";
import Profile from "../../components/SdkLandingPageComponents/profile";
import SdkDashboardLayout from "../SdkLandingPage/SdkDashboardLayout";
import CreateAccount from "../../components/SdkLandingPageComponents/createAccount";
import "./sdkStyles.css";
import InfoPage from "../../auth/sdkLandingPage/login/InfoPage";



const SDKLayout = () => {
  getAxiosInstance();
  const [userId, setUserId] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [doesSessionExistState, setDoesSessionExistState] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const id = await getUserId();
        setUserId(id);
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    })();

    (async () => {
      if (await Session.doesSessionExist()) {
        setDoesSessionExistState(true);
      }
    })();
  }, []);

  useEffect(() => {
    // console.log("doesSessionExistState: ", doesSessionExistState);
  }, [doesSessionExistState]);

  const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const sessionExists = doesSessionExistState;

    useEffect(() => {
      (async () => {
        if (sessionExists) {
          if (isEmailVerified) {
            return true;
          } else {
            navigate(sdkVerifyEmail);
          }
        } else {
          // console.log("Session does not exist, redirecting to login");
          navigate(sdkLogin);
        }
      })();
    }, [sessionExists]);

    return sessionExists ? children : null;
  };

  const PublicRoute = ({ children }) => {
    const navigate = useNavigate();
    const sessionExists = doesSessionExistState;

    useEffect(() => {
      (async () => {
        if (sessionExists) {
          // console.log("Session exists, redirecting to account");
          navigate(sdkAccount);
        }
      })();
    }, [navigate, sessionExists]);

    return !sessionExists ? children : null;
  };

  const PrivatChangePassword = ({ children }) => {
    const navigate = useNavigate();
    const sessionExists = doesSessionExistState;
    useEffect(() => {
      (async () => {
        if (!sessionExists) {
          // console.log("Session does not exist, redirecting to login");
          navigate(sdkLogin);
        } else {
          // console.log("Session exists, redirecting to change password");
          navigate(sdkChangePwd);
        }
      })();
    }, [navigate, sessionExists]);
  };

  // check is email verified
  useEffect(() => {
    const checkisEmailVerified = async () => {
      if (await Session.doesSessionExist()) {
        try {
          let accessTokenPayload =
            await Session.getAccessTokenPayloadSecurely();
          let isEmailVerified = accessTokenPayload["st-ev"]["v"];
          console.log(isEmailVerified, "is Email verified from app.js");
          setIsEmailVerified(isEmailVerified);
          return isEmailVerified;
        } catch (error) {
          console.error(
            "Error occurred while getting email verification status:",
            error
          );
          return false;
        }
      } else {
        return false;
      }
    };

    checkisEmailVerified();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<SdkLandingPage />} />

      <Route
        path="/account"
        element={
          <PrivateRoute>
            <SdkDashboardLayout isEmailVerified={isEmailVerified} />
          </PrivateRoute>
        }
      >
        <Route index element={<AccountInformation />} />
        <Route path="billing" element={<Billing />} />
        <Route path="api" element={<Apikeys />} />
        <Route path="/account/profile" element={<Profile />} />
      </Route>

      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/infoPage"
        element={
          <PublicRoute>
            <InfoPage />
          </PublicRoute>
        }
      />
      <Route
        path="/accept-invite"
        element={
          <PublicRoute>
            <Invitation />
          </PublicRoute>
        }
      />
      <Route
        path="/account-created"
        element={
          <PublicRoute>
            <AccountCreated />
          </PublicRoute>
        }
      />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/returnpolicy" element={<ReturnPolicy />} />
      <Route path="/auth/callback/thirdparty" element={<GoogleAuth />} />
      <Route path="/auth/callback/github" element={<GithubAuth />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {/* <Route path="/change-password" element={<PrivatChangePassword><ChangePassword /></PrivatChangePassword>} /> */}
      <Route
        path="/create-account"
        element={
          <PrivateRoute>
            <CreateAccount />
          </PrivateRoute>
        }
      />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/verify-email" element={<EmailConfirmation />} />
    </Routes>
  );
};

export default SDKLayout;
