import React from 'react'

function AppReview() {
    return (
        <div>
            <section className="flex items-center flex-col mx-auto py-16 px-4">


                <h2 className="text-center  text-2xl sm:text-3xl md:text-4xl text-transparent bg-clip-text max-w-max bg-gradient-to-r from-white via-white/80 to-white/30">
                    Our customers speak louder than we do,
                </h2>
                <h2 className="text-center  text-2xl sm:text-3xl md:text-4xl text-transparent bg-clip-text max-w-max bg-gradient-to-r from-white via-white/80 to-white/30">hear their experiences</h2>
            </section>
            {/* <section class="py-16 text-white">
                <div class="mt-8 flex flex-col lg:flex-row md:flex-col sm:flex-col justify-center items-center gap-4 space-x-8">
                    <div class="max-w-sm text-center">
                        <img src="https://bugbug.io/_next/image/?url=https%3A%2F%2Fbugbug-homepage.s3.eu-central-1.amazonaws.com%2F3_0669820864.png&w=128&q=85" alt="ILO apps logo" class="mx-auto mb-4"></img>
                        <p class="text-white-600">"We always wanted to automate but didn't have the resources. BugBug empowered us to do so. Very simple, quick to use, and looks really cool!"</p>
                        <p class="mt-4 text-white-800 font-semibold">— Kris, CEO, Innential</p>
                    </div>
                    <div class="max-w-sm text-center">
                        <img src="path/to/operto-logo.png" alt="Operto logo" class="mx-auto mb-4"></img>
                        <p class="text-white-600">"We tried different automation software with mixed results. That is until we found Autoflow, which suits our needs like a glove."</p>
                        <p class="mt-4 text-white-800 font-semibold">— Doan Nguyen, Quality Assurance Specialist</p>
                    </div>
                    <div class="max-w-sm text-center">
                        <img src="path/to/calcey-logo.png" alt="Calcey logo" class="mx-auto mb-4"></img>
                        <p class="text-white-600">"Very good and fast support from the Autoflow team. Have clear documentation which really helps you to get started. Features like scheduling test cases and getting results are perfect for our needs."</p>
                        <p class="mt-4 text-white-800 font-semibold">— Imalka, QA Engineer, Calcey.com</p>
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export default AppReview