import React from "react";
import { pricingData, sectionData } from "../../data";
import { layout } from "../../styles/styles";
import { SectionHeader } from "../SectionHeader";
import ComingSoonCard from "../cards/ComingSoonCard";
import PriceCard from "../cards/PriceCard";
import AutoFlowBasicCard from "../cards/AutoFlowBasicCard";

const Pricing = ({ sessionExists }) => {
  const freePlan = pricingData[0].free;
  return (
    <section className={`${layout.section}`} id="pricing">
      <SectionHeader
        title={sectionData[0].pricing.title}
        desc={sectionData[0].pricing.desc}
      />
      <div className="flex flex-col sm:flex-row items-center justify-center pb-36 gap-4">
        <PriceCard
          key={freePlan.id}
          title={freePlan.title}
          price={freePlan.price}
          tenure={freePlan.tenure}
          description={freePlan.description}
          features={freePlan.features}
        />
        {/* <ComingSoonCard /> */}
        <AutoFlowBasicCard sessionExists={sessionExists} />
      </div>
    </section>
  );
};

export default Pricing;
