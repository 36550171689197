import React from "react";
import { IoIosLogOut } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Session from "supertokens-web-js/recipe/session";
import { appBilling, appHome, appProfile, sdkAccount, sdkApiKeys, sdkBilling, sdkHome, sdkProfile } from "../../../routes/routes";
import { banner } from "../../../assets";


const Header = ({ userData,logFrom }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogOut = async () => {
    await Session.signOut();
    navigate(logFrom?appHome:sdkHome);
    window.location.reload();
  };

  return (
    <div className="relative">
      <div className="fixed top-0">
        <img src={banner} alt="banner" className="h-52 w-screen opacity-60" />
      </div>
      <header className="flex flex-row items-center justify-between mx-8 py-4 z-[950] relative">
        <div className="text-white">
          <h1 className="text-2xl font-primary font-bold">
            {location.pathname === sdkAccount
              ? "Dashboard"
              : location.pathname === sdkApiKeys
              ? "API"
              : location.pathname === sdkBilling||location.pathname === appBilling
              ? "Billing"
              : "Profile"}
          </h1>
        </div>
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost btn-circle avatar"
          >
            <div className="avatar placeholder">
              <div className="bg-primary-color text-black rounded-md w-8 sm:w-10">
                <span className="text-base sm:text-lg font-primary font-bold uppercase">
                  {userData ? userData.name.slice(0, 2) : "xx"}
                </span>
              </div>
            </div>
          </div>
          <ul
            tabIndex={0}
            className="mt-3 p-2 shadow menu menu-sm dropdown-content bg-gray-900/90 border border-[#1b1b1b] rounded-lg  sm:w-72"
          >
            <div className="my-2">
              <div className="flex flex-row items-center justify-start gap-x-2 px-2">
                <div className="avatar placeholder">
                  <div className="bg-primary-color text-black rounded-md w-11">
                    <span className="text-lg font-primary font-bold uppercase">
                      {userData ? userData.name.slice(0, 2) : "xx"}
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-white text-[11px] sm:text-base font-primary font-bold">
                    {userData && userData.name}
                  </p>
                  <p className="text-white/65 text-[10px] sm:text-[11px] font-secondary">
                    {userData && userData.email}
                  </p>
                </div>
              </div>
              <div className="border-b border-gray-700 my-2" />
              <div>
                <ul className="text-white active:text-white font-primary font-semibold">
                  <li className="py-1 hover:bg-primary-color rounded-md ">
                    <Link to={logFrom?appProfile:sdkProfile} className="text-base">
                      <p className="text-white hover:text-black text-sm sm:text-base">My Profile</p>
                    </Link>
                  </li>
                  <li
                    className="py-1 mt-1 hover:bg-primary-color rounded-md "
                    onClick={handleLogOut}
                  >
                    <Link className="text-base flex flex-row items-center justify-between">
                      <p className="text-white hover:text-black text-sm sm:text-base">Sign Out</p>
                      <IoIosLogOut />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
