import React from 'react'

function WhyAutoFlow() {
    return (
        <section className='h-112'>
            <div className="max-w-5xl px-4 mx-auto sm:px-6">
                <div className="pt-32 pb-24">
                    WhyAutoFlow
                </div>
            </div>
        </section>
    )
}

export default WhyAutoFlow