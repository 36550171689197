import React from "react";
import { SectionHeader } from "../SectionHeader";
import { sectionData, whyUseData } from "../../data";
import { layout } from "../../styles/styles";
import { CiCircleMinus } from "react-icons/ci";
import { IoIosAddCircleOutline } from "react-icons/io";

const WhyUse = () => {
  return (
    <section className={`${layout.section}`} id="whyuse">
      <div>
        <SectionHeader
          title={sectionData[0].whyUse.title}
          desc={sectionData[0].whyUse.desc}
        />
        <div className="text-center flex flex-col md:flex-row items-center justify-center sm:gap-4 px-4 sm:px-0">
          <div>
            <p className="text-sm md:text-xl font-bold font-primary text-secondary-color-dark bg-red-400/35 px-2 sm:px-10 py-1 rounded-lg">
              {whyUseData[0].conventionalApproach.heading}
            </p>
            <ul className="menu sm:w-[500px] text-white/75  bg-transparent  h-[450px] p-0 [&_li>*]:rounded-none">
              {whyUseData[0].conventionalApproach.data.map((data, index) => {
                return (
                  <li
                    className="py-1 h-[90px] sm:h-[110px] "
                    key={index}
                  >
                    <div className="gap-4">
                      <CiCircleMinus className="text-secondary-color-dark sm:text-2xl bg-red-400/35 rounded-full text-xl" />
                      <p className="sm:text-[15px] md:text-[17px] leading-normal cursor-text">
                        {data}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <p className="text-sm md:text-xl font-bold font-primary text-secondary-color-dark bg-primary-color px-2 sm:px-10 py-1 rounded-lg">
              {whyUseData[0].autoflowApproach.heading}
            </p>
            <ul className="menu sm:w-[500px] text-white h-[450px] bg-transparent p-0 [&_li>*]:rounded-none">
              {whyUseData[0].autoflowApproach.data.map((data, index) => {
                return (
                  <li
                    className="py-2 h-[90px] sm:h-[110px]"
                    key={index}
                  >
                    <div className="gap-4">
                      <IoIosAddCircleOutline className="text-secondary-color-dark md:text-2xl bg-primary-color rounded-full text-xl" />
                      <p className="sm:text-[15px] md:text-[17px] leading-normal cursor-text">
                        {data}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUse;
