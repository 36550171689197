import React from "react";
import AppIntroContent from "../../reUsableComponents/appIntroContent";

const AppBannerPage = () => {
  const gradientCircle1 =
    "-z-1 absolute left-1/2 h-[1026px] w-[1026px] -translate-x-1/2 transform rounded-full border-t border-zinc-500/20 bg-gradient-to-b from-zinc-900 from-0% via-transparent via-20% to-100%";
  const gradientCircle2 =
    "-z-1 absolute left-1/2 top-[160px] h-[684px] w-[684px] -translate-x-1/2 transform rounded-full border-t border-zinc-500/20 bg-gradient-to-b from-zinc-900 from-0% via-transparent via-20% to-100% shadow-2xl shadow-zinc-500/50";
  const gradientCircle3 =
    "-z-1 absolute left-1/2 top-[300px] h-[342px] w-[342px] -translate-x-1/2 transform rounded-full border-t border-zinc-500/20 bg-gradient-to-b from-zinc-900 from-0% via-transparent via-20% to-100% shadow-2xl shadow-zinc-500/50";
  return (
    <section>
      <div className="mx-auto max-w-5xl px-4 sm:px-6">
        <div className="relative mb-32 mt-12 overflow-hidden rounded-[32px] border border-zinc-500/20 px-8 pt-12 md:mt-16 md:pt-16">
          <div className="relative overflow-y-clip pb-16">
            <div className="-z-1 absolute left-1/2 -translate-x-1/2 transform">
              <div className="-z-1 relative">
                <div className={gradientCircle1}></div>
              </div>
              <div className="-z-1 relative">
                <div className={gradientCircle2}></div>
              </div>
              <div className="-z-1 relative">
                <div className={gradientCircle3}></div>
              </div>
            </div>

            <AppIntroContent bannerPage={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBannerPage;
