import React from 'react'

function InfoPage() {
    return (
        <div className="relative h-screen w-screen flex items-center justify-center bg-primary-color-dark overflow-hidden">
            <div
                className="flex items-center justify-center w-[200px] h-[200px] sm:w-[500px] sm:h-[500px] md:w-[650px] md:h-[650px] rounded-full"
                style={{
                    background:
                        "radial-gradient(circle at 50% 50%, #997529 0%, #985F2A 0%, #A1792D 0%, #985F2A 26%, #966125 41%, #825520 54%, #684323 71%, #2D2A2A 82%, #2D2A2A 100%)",
                    boxShadow: "4px 6px 95px 29px #8f681b inset",
                    animation: "colorChange1 5s infinite alternate",
                }}
            />

            <div className="absolute flex flex-row justify-center h-full w-screen">
                <div className="h-full w-full sm:w-1/2 backdrop-blur-[45px]">
                    <div className="relative flex flex-row items-center justify-center h-full w-full overflow-y-scroll scrollable-content">
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex justify-center flex-grow">
                                {/* <Link to={sdkHome}>
                                    <img
                                        src={logo_square_without_name}
                                        alt="logo"
                                        className="object-cover h-20 w-20"
                                    />
                                </Link> */}
                            </div>
                            <h1 className="text-white text-3xl md:text-4xl mt-5 md:mt-8 font-primary font-bold ">
                                Your email is verified successfully
                            </h1>
                            <p className="text-white text-base md:text-lg font-primary mt-4">
                            Please login to{" "}
                                <span className="border ml-2 px-2 py-[2px] rounded-xl bg-secondary-color font-bold heading shadow-top-left-bottom-right">
                                    Autoflow Studio App
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoPage