import React from "react";
import CursorHoverCard from "../../../Animations/CursorHoverCard";

const AppCard2 = ({
  content,
  icon,
  title,
  description,
  contentHeight,
  contentPadding,
}) => {
  return (
    <>
      <CursorHoverCard gradientColor="#d5cc2558" size={10} blur={300}>
        <div className="relative bg-black/20 h-full backdrop-blur-md rounded-[inherit] z-20 border border-secondary-color-light/5 overflow-hidden">
          <div className="relative bg-secondary-color-dark/10 h-full backdrop-blur-md rounded-[inherit] z-20 overflow-hidden">
            <div className="flex flex-col h-full">
              <div className="relative w-full p-2 overflow-hidden">
                <div
                  className={`relative flex items-center justify-center ${
                    contentPadding ? contentPadding : "p-8"
                  } overflow-hidden border border-secondary-color-light/10 ${
                    contentHeight ? contentHeight : "h-72"
                  } rounded-2xl  bg-black`}
                >
                  {content}
                </div>
              </div>
              <div className="flex items-start justify-center flex-1 p-8">
                <div className="flex flex-col items-center text-center">
                  <div className="flex items-center justify-center mb-2">
                    {icon}
                    <h5 className="inline-flex text-lg text-secondary-color-light/80 font-display">
                      {title}
                    </h5>
                  </div>
                  <p className="w-full text-zinc-400/80 font-display">
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CursorHoverCard>
    </>
  );
};

export default AppCard2;
