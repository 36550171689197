import React, { useEffect, useState } from "react";
import { codeData } from "../../data";

function Code({ selectedItem }) {
  const getCodeData = (item) =>
    codeData[item - 1]?.[`selected${item}`]?.code || [];

  const [copySuccess, setCopySuccess] = useState(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => setCopySuccess(true),
      () => setCopySuccess(false)
    );
  };

  useEffect(() => {
    setCopySuccess(null);
  }, [selectedItem]);

  const renderContent = () => {
    const selectedCode = getCodeData(selectedItem);

    return (
      <div className="relative">
        <div className="mockup-code scrollable-content">
          {selectedCode.map((code, index) => (
            <div key={index + 1} className="code-block">
              <pre data-prefix={index + 1} className="text-[10px] md:text-xs">
                <code className="text-[10px] md:text-base">{code}</code>
              </pre>
            </div>
          ))}
        </div>
        <button
          onClick={() => copyToClipboard(selectedCode)}
          className="absolute top-2 right-2 btn btn-xs glass"
        >
          {copySuccess === null
            ? "Copy"
            : copySuccess
            ? "Copied!"
            : "Copy Failed"}
        </button>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
}

export default Code;
