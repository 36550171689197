import React from "react";
import { Link } from "react-router-dom";
import {
  sdkPrivacy,
  sdkReturnPolicy,
  sdkTerms,
} from "../../../../../routes/routes";

const Footer = () => {
  return (
    <footer className="p-4 border-t border-black pb-28 xl:pb-4">
      <div className="flex flex-col items-center justify-center font-secondary bg-primary-color-dark">
        <iframe
          src="https://status.autoflow.tools/badge?theme=dark"
          width="250"
          height="30"
          frameborder="0"
          title="services"
          className="text-center ml-16 mb-2"
        ></iframe>
        <p className="text-white text-sm sm:text-base text-center">
          Copyright © 2024 Reveedo Technologies Pvt. Ltd.{" "}
          <br className="flex sm:hidden" /> All rights reserved.
        </p>
        <div className="flex flex-row items-center gap-4 underline cursor-pointer text-white text-sm sm:text-base">
          <Link to={sdkTerms}>
            <p>Terms of Service</p>
          </Link>
          <Link to={sdkPrivacy}>
            <p> Privacy Policy</p>
          </Link>
          <Link to={sdkReturnPolicy}>
            <p> Return Policy</p>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
