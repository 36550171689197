import React, { useEffect } from 'react'

const ReturnPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts
    }, []);
    return (
        <div className="p-6 pt-32 space-y-6 text-primary-color-light bg-secondary-color-dark h-screen font-secondary">
            <h1 className="text-2xl font-bold font-primary"> RETURN POLICY</h1>
            <p>Last updated January 11, 2024</p>
            <h2 className="text-xl font-bold font-primary">REFUNDS</h2>
            <p>All sales are final and no refund will be issued.</p>
            <h2 className="text-xl font-bold font-primary">QUESTIONS</h2>
            <p>If you have any questions concerning our return policy, please contact us at:</p>
            <p>contact@autoflowapp.com</p>
        </div>
    )
}

export default ReturnPolicy