import React from 'react'

/**
 * A styled lozenge component that displays a label with various background and text color options.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} [props.primary] - If true, applies primary brand styling.
 * @param {boolean} [props.success] - If true, applies success (green) styling.
 * @param {boolean} [props.remove] - If true, applies remove (red) styling.
 * @param {boolean} [props.progress] - If true, applies progress (blue) styling.
 * @param {boolean} [props.new] - If true, applies new (purple) styling.
 * @param {boolean} [props.moved] - If true, applies moved (orange) styling.
 * @param {boolean} [props.bold] - If true, applies bold text and background styling.
 * @param {React.ReactNode} [props.children] - The content to be displayed inside the lozenge.
 */
const Lozenge = (props) => {
  return (
    <div className={`text-body-sm rounded-sm p-50 font-bold w-fit ${
        props.primary ? props.bold ? "bg-surface-accent-brand-overlay-primary" : "bg-surface-accent-brand-primary text-brand"
        : props.success ? props.bold ? "bg-surface-accent-green-overlay-primary" : "bg-surface-accent-green-primary text-text-accent-green-primary"
        : props.remove ? props.bold ? "bg-surface-accent-red-overlay-primary" : "bg-surface-accent-red-primary text-text-accent-red-primary"
        : props.progress ? props.bold ? "bg-surface-accent-blue-overlay-primary" : "bg-surface-accent-blue-primary text-text-accent-blue-primary"
        : props.new ? props.bold ? "bg-surface-accent-purple-overlay-primary" : " bg-surface-accent-purple-primary text-text-accent-purple-primary"
        : props.moved ? props.bold ? "bg-surface-accent-orange-overlay-primary" : "bg-surface-accent-orange-primary text-text-accent-orange-primary"
        : props.bold ? "bg-neutral-200 text-text-primary"
        : "bg-alpha-800"
    } ${
        props.bold ? "text-text-inverse" : ""    
    }`}>
        {props.children}
    </div>
  );
}

export default Lozenge;
