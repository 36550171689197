import React, { useEffect } from 'react'
import { IoBagCheckOutline } from "react-icons/io5";
import { CiDollar } from "react-icons/ci";
import { IoHappyOutline } from "react-icons/io5";

function ForDevelopers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section class="flex  justify-center pt-32 md:pt-48 pb-32 md:pb-40">
        <header class="flex flex-col sm:flex-row sm:gap-4 md:gap-0 items-start sm:items-center lg:justify-between text-center mt-8 sm:mt-16 text-white px-4 sm:px-16 md:px-40 xl:px-96 w-full">
          <div class="flex flex-col text-left sm:w-[60%] md:w-auto">
            <h1 class=" text-sm font-semibold">For QAs</h1>
            <h2 class="block max-w-max text-2xl sm:text-4xl bg-gradient-to-r from-white via-white/80 to-white/30 bg-clip-text text-transparent mt-2 font-medium">Accelerate Testing while being Cost Efficient</h2>
            <p class="mt-4 max-w-md text-lg text-secondary-color-light/50">
              Effortless & quick quality testing with intuitive UI and detailed test records
            </p>
            <div class="flex items-center mt-6">
              {/* <form action="" class="mt-8 flex w-full flex-1 flex-col gap-2 sm:gap-0 sm:flex-row relative"> */}
              {/* <input type="text" placeholder="Email" name="email" class="rounded-lg sm:rounded-r-none border border-zinc-500/50 bg-gradient-to-b from-zinc-950 via-zinc-950 to-zinc-900 px-4 py-2 backdrop-blur-md transition placeholder:text-zinc-400/50 hover:border-secondary-color-light/40 text-secondary-color-light/50 outline-none font-display font-medium "/> */}
                <a href="/downloads" type="submit" name="subscribe" class="w-full text-center sm:w-auto text-black bg-primary-color font-display rounded-lg px-6 py-2 whitespace-nowrap">Get started for free</a>
              {/* </form> */}
            </div>
            <div class="flex items-center justify-center sm:justify-start space-x-4 mt-4 text-sm text-secondary-color-light/50">
              <div class="flex items-center">
                <svg class="w-4 h-4 mr-1 text-white" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
                  <path d="M5 13l4 4L19 7"></path>
                </svg>
                Affordable Plans
              </div>
              <div class="flex items-center">
                <svg class="w-4 h-4 mr-1 text-white" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
                  <path d="M5 13l4 4L19 7"></path>
                </svg>
                Quick Setup
              </div>
            </div>
          </div>
          <div class="mt-8 sm:mt-0 md:mt-8 w-full  sm:w-[40%] md:w-auto">
            <img src={'https://ik.imagekit.io/du5yq7zec/Landing%20Page/forQA-1.png?updatedAt=1724664158494'} alt="Recorder Screenshot" class="w-full max-w-xl mx-auto object-contain md:w-auto md:h-[350px] xl:h-[450px]  rounded-lg "></img>
          </div>
        </header>
      </section>

      <section class="pb-32 md:pb-40 text-center text-white px-4 sm:px-0">
        <h3 class="text-2xl md:text-3xl font-medium">Why should QAs use Autoflow Studio?</h3>
        <div class="flex justify-center gap-4 sm:gap-8 mt-8 w-full sm:w-4/5 md:w-3/5 mx-auto">
          <div class="flex flex-col items-center w-1/3">
            <div class="bg-[#faad1b] p-4 rounded-xl">
              <IoHappyOutline className="w-8 h-8 text-zinc-800 stroke-[0.25]" />
            </div>
            <p class="mt-2 font-semibold">User-friendly, intuitive UI</p>
          </div>
          <div class="flex flex-col items-center w-1/3">
            <div class="bg-[#faad1b] p-4 rounded-xl">
              <IoBagCheckOutline className="w-8 h-8 text-zinc-800" />
            </div>
            <p class="mt-2 font-semibold">Simple, but feature-packed</p>
          </div>
          <div class="flex flex-col items-center w-1/3">
            <div class="bg-[#faad1b] p-4 rounded-xl">
              <CiDollar className="w-8 h-8 text-zinc-800 stroke-[0.5]" />
            </div>
            <p class="mt-2 font-semibold">Cost-effective, unlimited local test runs</p>
          </div>
        </div>
      </section>

      <section class="flex  justify-center pb-32 md:pb-40 w-full">
        <header class="flex flex-col lg:flex-row mt-16 items-center gap-4 text-white md:justify-center lg:gap-20 xl:gap-40 px-4 sm:px-48 md:px-40 xl:px-96 w-full">
          <div class="flex flex-col">
            <h2 class="text-2xl md:text-3xl font-medium mt-2">How Autoflow Simplifies Test Automation for QAs</h2>
            <p class="mt-4 max-w-md text-lg text-secondary-color-light/50">
              <ul className='list-disc ml-4 list-outside'>
                <li className="text-lg mb-3 text-secondary-color-light/50">Intuitive and clean UI</li>
                <li className="text-lg mb-3 text-secondary-color-light/50">Test creation in under 5 minutes</li>
                <li className="text-lg mb-3 text-secondary-color-light/50">Automated regression testing</li>
                <li className="text-lg mb-3 text-secondary-color-light/50">No code interface</li>
              </ul>
            </p>
          </div>
          <div class="w-full md:w-fit">
            <img src={'https://ik.imagekit.io/du5yq7zec/Landing%20Page/flow_list?updatedAt=1724667322150'} alt="Recorder Screenshot" class="w-full object-contain md:w-auto md:h-[300px] lg:h-[400px] rounded-lg "></img>
          </div>
        </header>
      </section>


      <section class="flex justify-center pb-32 md:pb-40">
        <header class="flex flex-col-reverse lg:flex-row mt-16 items-center gap-4 text-white md:justify-around xl:justify-center lg:gap-20 xl:gap-40 px-4 sm:px-48 md:px-40 xl:px-96 w-full">
          <div class="w-full md:w-fit">
            <img src={'https://ik.imagekit.io/du5yq7zec/Landing%20Page/input-step-edit-modal.png?updatedAt=1724667720234'} alt="Recorder Screenshot" class="w-full object-contain md:w-auto md:h-[300px] lg:h-[400px] rounded-lg "></img>
          </div>
          <div class="flex flex-col w-full md:w-auto">
            <h2 class="text-2xl md:text-3xl font-medium mt-2">Advanced Features As and When You Need Them</h2>
            <p class="mt-4 max-w-md text-lg text-secondary-color-light/50">
              <ul className=' list-disc ml-4 list-outside'>
                <li className="text-lg mb-2 text-secondary-color-light/50">Modify headers</li>
                <li className="text-lg mb-2 text-secondary-color-light/50">Generate random data</li>
                <li className="text-lg mb-2 text-secondary-color-light/50">Create variables & use them everywhere</li>
                <li className="text-lg mb-2 text-secondary-color-light/50">Use auto-selectors</li>
              </ul>
            </p>
          </div>
        </header>
      </section>

      <section class="flex  justify-center pb-32 md:pb-40">
        <header class="flex flex-col lg:flex-row md:flex-col sm:flex-col mt-16 items-center gap-4 text-white md:justify-center lg:gap-20 xl:gap-40 px-4 sm:px-48 md:px-40 xl:px-96 w-full">
          <div class="flex flex-col w-full md:w-auto">
            <h2 class="text-2xl md:text-3xl font-medium mt-2">Conduct Tests without Exceeding Your Budget</h2>
            <p class="mt-4 max-w-md text-lg text-secondary-color-light/50">
              <ul className='list-disc ml-4 list-outside'>
                <li className="text-lg mb-3 text-secondary-color-light/50">Run cloud tests with no infrastructure costs</li>
                <li className="text-lg mb-3 text-secondary-color-light/50">Collaborate with unlimited users </li>
                <li className="text-lg mb-3 text-secondary-color-light/50">Conduct unlimited browser tests</li>
              </ul>
            </p>
          </div>
          <div class="w-full md:w-fit">
            <img src={'https://ik.imagekit.io/du5yq7zec/Landing%20Page/test-run-modal.png?updatedAt=1724667824169'} alt="Recorder Screenshot" class="w-full md:w-auto md:h-[400px] object-contain rounded-lg "></img>
          </div>
        </header>
      </section>

      <section class="flex  justify-center pb-32 md:pb-40">
        <header class="flex flex-col-reverse lg:flex-row mt-16 items-center sm:items-start gap-4 text-white md:items-center md:justify-between xl:justify-center lg:gap-20 xl:gap-40 px-4 sm:px-48 md:px-40 xl:px-96 w-full">
          <div class="w-full md:w-fit">
            <img src={'https://ik.imagekit.io/du5yq7zec/Landing%20Page/create-schedule-modal.png?updatedAt=1724667964344'} alt="Recorder Screenshot" class="w-full object-contain md:w-auto md:h-[300px] rounded-lg "></img>
          </div>
          <div class="flex flex-col w-full sm:w-auto">
            <h2 class="text-xl font-bold mt-2">Detect bugs faster</h2>
            <p class="mt-4 max-w-md text-lg text-secondary-color-light/50">
              <ul className='list-disc ml-4 list-outside'>
                <li className="text-lg mb-3 text-secondary-color-light/50">Schedule regular tests to ensure proper application performance</li>
                <li className="text-lg mb-3 text-secondary-color-light/50">Get notified of any issues immediately</li>
              </ul>
             </p>
          </div>
        </header>
      </section>

      <section class="flex  justify-center pb-32 md:pb-40">
        <header class="flex flex-col mt-16 items-center  px-4 gap-2 text-white sm:px-16 md:px-40 xl:px-96">
          <div class="text-center">
            <h3 class="text-2xl md:text-3xl font-medium ">Integrate with Preferred Tools</h3>
            <p class="mt-4 max-w-2xl mx-auto text-lg text-secondary-color-light/50">
            Use Autoflow API and run seamless cloud tests in your CI/CD pipelines</p>
          </div>
          {/* <div class="flex flex-wrap justify-center items-center mt-8 space-x-8">
            <img src="https://bugbug-homepage.s3.eu-central-1.amazonaws.com/Zapier_logo_d9bfd11ea6.svg" alt="Zapier" class="w-32 h-16"></img>
            <img src="https://bugbug.io/_next/image/?url=https%3A%2F%2Fbugbug-homepage.s3.eu-central-1.amazonaws.com%2FJenkins_logo_with_title_1_acd8f9dff2.png&w=256&q=85" alt="Jenkins" class="h-16 max-w-xs"></img>
            <img src="https://bugbug-homepage.s3.eu-central-1.amazonaws.com/Git_Hub_Logo_89c936990e.svg" alt="GitHub" class="h-16 w-32"></img>
            <img src="https://bugbug-homepage.s3.eu-central-1.amazonaws.com/LOGO_2_802b2f51d4.svg" alt="GitLab" class="h-16 w-32"></img>
            <img src="https://bugbug-homepage.s3.eu-central-1.amazonaws.com/Bitbucket_Logo_blue_75a210e48f.svg" alt="Bitbucket" class="h-16 w-32"></img>
          </div> */}
        </header>
      </section>

      <section class="flex  justify-center pb-32 md:pb-40">
        <header class="flex flex-col lg:flex-row md:flex-col sm:flex-col mt-16 items-center px-4 gap-2 text-white lg:justify-center lg:gap-16 md:px-40 xl:px-96">
          <div class="mt-8 ">
            <img src={'https://ik.imagekit.io/du5yq7zec/Landing%20Page/tutorialPic.png?updatedAt=1724665982225'} alt="Recorder Screenshot" class="w-full sm:w-auto object-contain sm:h-[250px] rounded-lg "></img>
          </div>
          <div class="flex flex-col gap-2 w-[100%] lg:grow lg:w-auto text-center md:text-left">
            <h2 class="text-2xl font-medium mt-2">Automate your first test with Autoflow Studio</h2>
            <p class="mt-2 max-w-md text-lg text-secondary-color-light/50">
            Are you just starting out in the test automation world? We’ve got just the solution for you.</p>
            <button class="bg-[#faad1b] text-black px-6 py-2 rounded-xl mt-3 font-medium">
            Read the documentation
            </button>
          </div>
        </header>
      </section>

      <section class="py-16 text-center text-white px-4 sm:px-36 md:px-0">
      <h2 class="text-3xl font-medium">Autoflow Offers User-Friendly and Advanced Features for All</h2>
      <div class="flex justify-center mt-8 space-x-4 md:space-x-16">
        <div className="flex gap-6 items-center w-1/2 md:w-auto">
          <div class="hidden md:flex justify-center items-center w-16 h-16 bg-[#faad1b] rounded-full text-black">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="28px" height="28px">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M1 21h4V9H1v12zM23 10.5c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83V19c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.85-1.22l3.02-7.05c.09-.23.13-.47.13-.73v-1.5z"/>
            </svg>
          </div>
          <div className="flex flex-col items-start gap-3">
            <p class="text-secondary-color-light/50">All the features you need, regardless of your testing expertise</p>
            <button class="btn-warning btn btn-outline text-white px-4 py-2 rounded-full hover:bg-[#faad1b] w-full md:w-auto">
            Explore Features
            </button>
          </div>
        </div>
        <div className="flex gap-6 items-center w-1/2 md:w-auto">
          <div class="hidden md:flex justify-center items-center w-16 h-16 bg-[#faad1b] rounded-full text-black">
            &gt;_
          </div>
          <div className="flex flex-col items-start gap-3">

            <p class="text-secondary-color-light/50">Start Learning Testing with Autoflow’s Step-by-Step Approach.</p>
            <button class="btn-warning btn btn-outline text-white px-4 py-2 rounded-full hover:bg-[#faad1b] w-full md:w-auto ">
              <a>View Documentation</a>
            </button>
          </div>
        </div>
      </div>
    </section>

      <section class="py-16 text-white">
        <h2 class="text-3xl font-medium text-center ">See what other Users are saying</h2>
        <div class="mt-12 flex flex-col gap-y-12 lg:gap-y-0 lg:grid grid-cols-3 md:flex-col lg:justify-items-center sm:flex-col justify-center items-center gap-4 lg:space-x-8">
          <div class="max-w-sm text-center h-full">
            <div className="w-[60%] p-3 bg-white rounded-lg h-[5.5rem] mx-auto lg:h-1/3 lg:flex lg:items-center lg:justify-center">

              <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/doqyfy-logo.webp?updatedAt=1726068154309" alt="Doqfy logo" class="border-white h-full lg:h-[75%] max-w-full max-h-full object-contain mx-auto mb-4 lg:mb-0"></img>
            </div>
            <p class="text-white-600 mt-4 lg:mt-8">"Thanks to AutoFlow, we now save 2-3 hours after every deployment. What stood out to us was how effortless it is to integrate custom logic into our tests. Although the existing features handle most of our requirements, customizing for specific cases is a breeze whenever needed."</p>
            <p class="mt-4 text-white-800 font-semibold">— Aishwarya, QA tester</p>
          </div>
          <div class="max-w-sm text-center h-full">
            <div className="w-[60%] p-3 bg-white rounded-lg mx-auto h-20 lg:h-1/3 lg:flex lg:items-center lg:justify-center">
            
              <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/kenverse-logo.png?updatedAt=1726124743420" alt="Operto logo" class="h-full max-w-full max-h-full object-contain mx-auto mb-4 lg:mb-0"></img>
            </div>
            <p class="text-white-600 mt-4 lg:mt-8">"We experimented with various automation tools but had a mixed experience. Then we discovered AutoFlow, and it turned out to be the perfect fit for our needs."</p>
            <p class="mt-4 text-white-800 font-semibold">— Varunprasad, Quality Assurance Specialist</p>
          </div>
          <div class="max-w-sm text-center h-full">
            <div className="w-[60%] p-3 bg-white rounded-lg mx-auto h-[5.5rem] lg:h-1/3 lg:flex lg:items-center lg:justify-center">
              <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/optimile-logo.svg?updatedAt=1726124740098" alt="Calcey logo" class="h-full lg:h-[85%] max-w-full max-h-full object-contain mx-auto mb-4 lg:mb-0"></img>
            </div>
            <p class="text-white-600 mt-3 lg:mt-8">"The support from the AutoFlow team is excellent and quick. Their clear documentation makes it easy to get up and running. Features like test case scheduling and result tracking are exactly what we were looking for."</p>
            <p class="mt-4 text-white-800 font-semibold">— Srikanth, Co-Founder and CEO, Optimile</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default ForDevelopers