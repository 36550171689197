import React, { useEffect, useState } from "react";

import { BsArrowUpSquareFill } from "react-icons/bs";
import { Outlet } from "react-router-dom";
import { Link, animateScroll } from "react-scroll";
import AppFooter from "../AppFooter";
import AppHeader from "../AppHeader";

const AppLandingPageLayout = () => {
  const [scrollTop, setScrollTop] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 500) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <div className="bg-black/90 w-full h-full">
      <AppHeader />
      <div>
        <Outlet />
      </div>
      <AppFooter />
      {scrollTop && (
        <Link
          smooth={true}
          duration={1000}
          className="fixed bottom-16 right-5 sm:bottom-5 sm:right-40"
        >
          <button
            onClick={scrollToTop}
            className="btn btn-sm btn-ghost px-1 drop-shadow-2xl opacity-65"
          >
            <BsArrowUpSquareFill className="text-2xl sm:text-3xl text-warning shadow-inner" />
          </button>
        </Link>
      )}
    </div>
  );
};

export default AppLandingPageLayout;
