import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { thirdPartySignInAndUp, signOut } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { appCreateAccount, sdkLogin, studioLogin } from "../../routes/routes";
import LoadingPage from "./LoadingPage";
import SessionReact, { getUserId } from "supertokens-auth-react/recipe/session";
import Session from "supertokens-web-js/recipe/session";
import { profile3 } from "../../api/services/apiService";
import { logo_square_without_name } from "../../assets";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { set } from "react-hook-form";

const AppThirdPartyAuth = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [doesSessionExistState, setDoesSessionExistState] = useState(false);
    const [userId, setUserId] = useState("");
    const [jwt, setJwt] = useState("");
    const [userData, setUserData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const thirdPartyId = queryParams.get("tid");



    async function checkForNameAndOrganisation() {
        try {
            const profileData = await profile3();
            console.log("Profile data is", profileData);
            if (
                !(profileData.name && profileData.name.length >= 0) ||
                !profileData.organisation
            ) {
                console.log("Either Name or Organisation not Present!!! Navigating to create account");
                return false;
            } else {
                console.log("Name and Organisation are present");
                return true;
            }
        } catch (error) {
            console.error("Error fetching profile data:", error);
        }
    }

    async function handleThirdPartyAuthCallback() {
        try {
            const response = await thirdPartySignInAndUp();
            console.log("Response from API signinup is", response);
            if (response.status === "OK") {
                setUser(response.user);
                if (
                    response.createdNewRecipeUser &&
                    response.user.loginMethods.length === 1
                ) {
                    console.log("New user created");
                } else {
                    console.log("User logged in");
                }

                if (await SessionReact.doesSessionExist()) {
                    setDoesSessionExistState(true);
                    let userId = await Session.getUserId();
                    let jwt = await Session.getAccessToken();
                    let accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
                    setUserData(accessTokenPayload);
                    console.log("User Data is", accessTokenPayload);

                    setUserId(userId);
                    setJwt(jwt);

                    console.log("User ID is", userId);
                    console.log("JWT is", jwt);

                
                    if (await checkForNameAndOrganisation()) {
                        const link = document.createElement('a');
                        link.href = `autoflow://signinup?accessToken=${jwt}&userId=${userId}&email=${accessTokenPayload?.user_info?.email}&organisation=${accessTokenPayload?.user_info?.organisation_name}&name=${accessTokenPayload?.user_info?.first_name + " " + accessTokenPayload?.user_info?.last_name}`;
                        document.body.appendChild(link);
                        link.click();
                    } else {
                        navigate(appCreateAccount);
                    }
                }

            } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
                // this can happen due to automatic account linking. Please see our account linking docs
            } else {
                toast.error(
                    "No email provided by social login. Please use another form of login"
                );
                window.location.assign(sdkLogin); // redirect back to login page
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                toast.error(err.message);
            } else if (err.status === 409) {
                toast.error("Email already exists!");
                navigate(studioLogin, {
                    state: {
                        message:
                            "It appears that you've previously logged in using a different method",
                        source: "third party",
                    },
                });
            } else {
                navigate(studioLogin, {
                    state: {
                        message: "Oops! Something went wrong.",
                        source: "third party",
                    },
                });
                toast.error("Oops! Something went wrong.");
            }
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    }

    useEffect(() => {
        async function checkSession() {
            if (await SessionReact.doesSessionExist()) {
                setDoesSessionExistState(true);
                let userId = await Session.getUserId();

                let accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
                setUserData(accessTokenPayload);
                console.log("User Data is", accessTokenPayload);

                let jwt = await Session.getAccessToken();
                setUserId(userId);
                setJwt(jwt);
                console.log("User ID is", userId);
                console.log("JWT is", jwt);

                if (await checkForNameAndOrganisation()) {
                    const link = document.createElement('a');
                    link.href = `autoflow://signinup?accessToken=${jwt}&userId=${userId}&email=${accessTokenPayload?.user_info?.email}&organisation=${accessTokenPayload?.user_info?.organisation_name}&name=${accessTokenPayload?.user_info?.first_name + " " + accessTokenPayload?.user_info?.last_name}`;
                    document.body.appendChild(link);
                    link.click();
                    setLoading(false);
                } else {
                    navigate(appCreateAccount);
                }
            } else {
                console.log(`Third Party ID is ${thirdPartyId}`);
                if (thirdPartyId === "google") {
                    handleThirdPartyAuthCallback();
                }
                else if (thirdPartyId === "github") {
                    console.log("Github Callback");
                    handleThirdPartyAuthCallback();
                }
                else {
                    navigate(studioLogin);
                }
            }
        }
        checkSession();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const id = await getUserId();
                console.log("Fetched User ID is", id);
                setUserId(id);
            } catch (error) {
                console.error("Error fetching user ID:", error);
            }
        })();
    }, []);

    const handleLogout = async () => {
        await signOut();
        setUser(null);
        navigate(studioLogin);
    };

    const handleOpenStudioApp = () => {
        const link = document.createElement('a');
        link.href = `autoflow://signinup?accessToken=${jwt}&userId=${userId}&email=${userData?.user_info?.email}&organisation=${userData?.user_info?.organisation_name}&name=${userData?.user_info?.first_name + " " + userData?.user_info?.last_name}`;
        document.body.appendChild(link);
        link.click();
    };

    const handleGoToDashboard = () => {
        navigate('/account/billing');
        window.location.reload();
    };

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <div
            className="bg-black h-screen w-screen flex flex-col items-center justify-center overflow-x-auto relative"
            style={{
                background:
                    "linear-gradient(90deg, #2B2B02 0%, #0D1010 29%, #000000 48%, #000000 74%, #262B0C 100%)",
            }}
        >
            <div className="fixed top-5 right-5">
                <button
                    className="btn btn-sm btn-warning text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300"
                    onClick={handleLogout}
                >
                    Sign Out
                </button>
            </div>

            <div className="flex flex-col items-center justify-center mb-6">
                <div className="flex justify-center flex-grow">
                    <Link to={"/"}>
                        <img
                            src={logo_square_without_name}
                            alt="logo"
                            className="object-cover h-20 w-20"
                        />
                    </Link>
                </div>
                <p className="text-white text-base md:text-xl font-primary font-semibold mt-4">
                    Welcome to{" "}
                    <span className="font-bold text-primary-color">Autoflow</span>
                </p>
            </div>
            {userId ? (
                <div className="p-6 rounded-lg shadow-md text-center">
                    <h2 className="text-3xl font-bold text-primary-color mb-4">It's great to have you onboard</h2>
                    <h2 className="text-3xl font-bold text-white mb-4">{userData?.user_info?.first_name} {userData?.user_info?.last_name}</h2>
                    <p className="text-primary-color font-bold mb-8">Redirecting you to Desktop App</p>

                    {/* <p className="text-white mb-12">UserId: {userId}</p> */}
                    <div className="flex flex-col space-y-4">
                        <button
                            onClick={() => setShowModal(true)}

                            className="bg-primary-color text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300 mb-3"
                        >
                            Open Studio App
                        </button>
                        <button
                            onClick={handleGoToDashboard}
                            className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-900 transition duration-300"
                        >
                            Go to Dashboard
                        </button>
                    </div>
                </div>
            ) : (
                <div className="text-center">
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">Login Failed</h2>
                    <button
                        onClick={() => navigate(studioLogin)}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                    >
                        Go to Login Page
                    </button>
                </div>
            )}

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80">
                    <div className="bg-black p-6 rounded-lg shadow-lg text-center relative"
                        style={{
                            background:
                                "linear-gradient(90deg, #2B2B02 0%, #0D1010 29%, #000000 48%, #000000 74%, #262B0C 100%)",
                        }}>
                        <button
                            onClick={() => setShowModal(false)}
                            className="absolute top-2 right-2 text-primary-color"
                        >
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                        </button>
                        <h2 className="text-2xl text-primary-color font-bold mb-4">Open AutoFlow Studio</h2>
                        <p className="mb-4 text-white">You are about to open the Studio App.</p>
                        <button
                            onClick={handleOpenStudioApp}
                            className="bg-primary-color text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300"
                        >
                            Open App
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppThirdPartyAuth;