import React from "react";
import CursorHoverCard from "../../../Animations/CursorHoverCard";

const AppCard1 = ({
  icon,
  title,
  description,
  content,
  descWidth,
  contentHeight,
  type
}) => {
  return (
    <>
      <CursorHoverCard gradientColor="#d5cc2558" size={10} blur={200}>
        <div className="relative bg-black/50 h-full backdrop-blur-md rounded-[inherit] z-20 overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="flex items-start justify-center flex-1 p-8">
              <div className="flex flex-col items-center text-center">
                {icon}
                <h5 className="inline-flex py-2 text-xl text-secondary-color-light font-display font-normal">
                  {title}
                </h5>
                <p
                  className={`${descWidth} text-zinc-400/80 font-display font-normal`}
                >
                  {description}
                </p>
              </div>
            </div>
            <div className="relative w-full p-2 overflow-hidden">
              <div
                className={`relative flex ${type && type == 'img' ? '' : 'items-center justify-center px-8'} overflow-hidden border border-secondary-white/10 ${contentHeight ? contentHeight : "h-80"
                  } rounded-2xl bg-gradient-to-b from-black/30 to-zinc-900/40`}
              >
                {content}
              </div>
            </div>
          </div>
        </div>
      </CursorHoverCard>
    </>
  );
};

export default AppCard1;
