import React from "react";
import SparkleBackground from "../../../Animations/SparkleBackground";
import AppIntroContent from "../../reUsableComponents/appIntroContent";
import YoutubeEmbed from "./YoutubeEmbed";

const AppHero = () => {
  return (
    <section className="px-4 sm:px-8 pt-32 md:pt-48">
      <div className="text-center flex flex-col items-center justify-center -mb-14 md:-mb-20">
        <SparkleBackground>
          <AppIntroContent />
        </SparkleBackground>
        <div className="mt-8 sm:mt-14 py-2 px-4 sm:px-8 md:px-2">
          <div className="relative inline-flex p-[2px] overflow-hidden rounded-lg md:rounded-xl">
            <span className="absolute inset-[-1000%] animate-[spin_16s_linear_infinite] bg-[conic-gradient(from_225deg_at_50%_50%,_theme(colors.transparent)_0%,_theme(colors.transparent)_92%,_theme(colors.yellow.600)_97%,_theme(colors.transparent)_100%)]"></span>
            <span className="absolute inset-[-1000%] animate-[spin_16s_linear_infinite_8s] bg-[conic-gradient(from_225deg_at_50%_50%,_theme(colors.transparent)_0%,_theme(colors.transparent)_92%,_theme(colors.yellow.600)_97%,_theme(colors.transparent)_100%)]"></span>
            <div className="relative inline-flex items-center justify-center rounded-lg bg-zinc-950 ring ring-zinc-500/25 md:rounded-xl lg:h-[720px] lg:w-[1280px] sm:w-[720px] sm:h-[480px] xs:w-[640px] xs:h-[480px]">
              <YoutubeEmbed />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppHero;
