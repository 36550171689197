import React from 'react'
import CursorHoverCard from '../../../Animations/CursorHoverCard'
import { clientsData } from './data'
import { Link } from 'react-router-dom'

function AppCards() {
    return (
        <section class="flex justify-center items-center px-4 sm:px-8 md:px-0 pt-32 md:pt-48">
            <div class="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-3 gap-6">
                {
                    clientsData?.map((item) => (
                        <CursorHoverCard gradientColor="#d5cc2558" size={2} blur={100}>
                            <div class="relative border border-secondary-color-light/5 h-96 rounded-lg shadow-md p-4 md:p-6 text-center">
                                <div class="text-4xl text-white mb-4">{item.icon}</div>
                                <h2 class="text-xl text-white mb-2" style={{ fontFamily: "Plus Jakarta Sans Variable, sans-serif" }}>{item.name}</h2>
                                <p class="text-gray-400 mb-4 h-12" style={{ fontFamily: "Plus Jakarta Sans Variable, sans-serif" }}>{item.title}</p>
                                <ul class="text-left mb-4 text-gray-400">
                                    <li class="flex items-center">
                                        <span class="mr-2" style={{ color: " #faad1b" }}>✔</span>{item.sub1}
                                    </li>
                                    <li class="flex items-center">
                                        <span class="mr-2" style={{ color: "#faad1b" }}>✔</span> {item.sub2}
                                    </li>
                                </ul>
                                <div className='absolute bottom-5 left-1/2 transform -translate-x-1/2'>
                                    <button  class="bg-[#faad1b] text-black px-4 py-2 rounded-xl hover:bg-yellow-600">
                                      <Link to={item.link}>Explore benefits</Link>
                                    </button>
                                </div>
                            </div>
                        </CursorHoverCard>
                    ))
                }

            </div>
        </section>
    )
}

export default AppCards