import React from "react";

const Input = ({ label, type, inputWidth, placeholder, register, error }) => (
  <div className="flex flex-col justify-center -mb-2">
    <label className="form-control w-full max-w-xs">
      <div className="label -mb-1">
        <span className="label-text font-secondary text-white/70">{label}</span>
      </div>
      <input
        type={type}
        placeholder={placeholder}
        {...register}
        className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary ${inputWidth} ${
          error && "border-red-500 focus:border-red-500"
        }`}
      />
      <div className="label">
        <span className="label-text-alt">
          {error && <p className="text-red-500">{error.message}</p>}
        </span>
      </div>
    </label>
  </div>
);

export default Input;
