import React from "react";
import AppCard1 from "../../reUsableComponents/cards/AppCard1";
import AppCard3 from "../../reUsableComponents/cards/AppCard3";
import SparkleBackground from "../../../Animations/SparkleBackground";
import AppSectionHeader from "../../reUsableComponents/AppSectionHeader";
import {
  AppPlatformcardData,
  app_platform_featData,
} from "../../../../utils/Data";

const AppPlatform = () => {
  const renderCards = () => {
    return AppPlatformcardData.map((card, index) => (
      <div className={card.styles} key={index}>
        <div className="relative w-full h-full bg-gradient-to-b from-primary-color-light/10 to-primary-color-light/5 rounded-3xl p-px overflow-hidden">
          <AppCard1
            icon={card.icon}
            title={card.title}
            description={card.description}
            descWidth="w-3/4"
            content={card.content}
            type={card.type}
          />
        </div>
      </div>
    ));
  };

  return (
    <section className="py-12 md:py-16 px-4 md:px-0">
      <SparkleBackground>
        <AppSectionHeader
          title="Platform Agnostic"
          mainTitle="Built for Developers, QAs and Product Owners"
          description="Now everyone has the power to test their applications."
        />
      </SparkleBackground>
      <div className="max-w-5xl px-4 mx-auto sm:px-6">
        <div className="relative pb-12 md:pb-16">
          <div className="grid gap-6 md:grid-cols-12 group">
            {renderCards()}
          </div>
        </div>
        <div className="grid gap-8 sm:grid-cols-3 md:gap-12">
          {app_platform_featData.map((feature, index) => (
            <div className="pl-4" key={index}>
              <AppCard3
                icon={<feature.icon className="text-white" />}
                title={feature.title}
                description={feature.description}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AppPlatform;
