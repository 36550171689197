import React, { useState, useEffect } from "react";
import SparkleBackground from "../../../components/Animations/SparkleBackground";
import AppSectionHeader from "../../../components/AppLandingPageComponents/reUsableComponents/AppSectionHeader";
import { AnimatePresence,motion } from "framer-motion";
import { FaApple,FaLinux,FaGlobe,FaWindows } from "react-icons/fa";


const DownloadsPage = () => {
  const [downloadOS, setDownloadOS] = useState("Mac");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "Download AutoFlow Studio | Get Started";
  }, [])

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative mx-auto max-w-5xl px-4 sm:px-6">
          <div className="pb-28 pt-32 h-fit">
            {/* <SparkleBackground>
              <AppSectionHeader
                title="Download"
                mainTitle="Get Early Access"
                description="Fill the form below to get early access to Autoflow Studio."
              />
            </SparkleBackground>
            {isLoading && (
              <div className="flex items-center justify-center">
                <span className="loading loading-infinity text-warning w-20 opacity-50"></span>
              </div>
            )}
            <iframe
              src="https://tally.so/embed/nP65D5?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
              // loading="lazy"
              className={`h-[900px] sm:h-[800px] w-full rounded-md ${
                isLoading ? "hidden" : ""
              }`}
              title="Get Early Access to AutoFlow - DownloadPage"
              onLoad={handleLoad}
            ></iframe> */}

            <div className="mx-auto max-w-4xl">
              <div className="hide-scroll mb-10 flex w-full min-w-0 gap-4 overflow-x-auto">
                <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Mac"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("Mac")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaApple className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Mac
                  </div>
                </button>
                <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Windows"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("Windows")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaWindows className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Windows
                  </div>
                </button>
                {/* <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Linux"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("Linux")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaLinux className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Linux
                  </div>
                </button> */}
                {/* <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "Web"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("Web")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaGlobe className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    Web App
                  </div>
                </button> */}
                {/* <button
                  className={`my-2 inline-flex flex-shrink-0 grow flex-col items-center justify-center rounded-xl border  px-8 py-4 text-center  transition-opacity hover:bg-zinc-500/10  bg-zinc-500/5  ${
                    downloadOS === "CLI"
                      ? "border-zinc-400/10 opacity-100"
                      : "border-transparent opacity-50"
                  }`}
                  onClick={() => setDownloadOS("CLI")}
                >
                  <div className="mb-4 flex flex-shrink-0 rounded-xl border border-zinc-600 bg-zinc-900 p-4">
                    <FaApple className="h-6 w-6 text-secondary-color-light" />
                  </div>
                  <div className="text-sm leading-tight text-secondary-color-light">
                    CLI
                  </div>
                </button> */}
              </div>
              <div className="relative flex flex-col pt-12">
                <div className="flex w-full justify-center">
                  {downloadOS === "Mac" ? (
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={"Mac"}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className="flex flex-col items-stretch gap-4">
                          <a
                            href="https://dl.getautoflowstudio.com/mac"
                            className="relative inline-flex flex-shrink-0 text-secondary-color-light font-heading items-center justify-center rounded-xl border border-zinc-500/50 bg-zinc-500/10 px-3 py-2 text-sm transition hover:border-zinc-500/80"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download for Mac &nbsp; ~ &nbsp; Apple Silicon
                          </a>
                          {/* <a
                            href="/"
                            className="relative inline-flex flex-shrink-0 text-secondary-color-light font-heading items-center justify-center rounded-xl border border-zinc-500/50 bg-zinc-500/10 px-3 py-2 text-sm transition hover:border-zinc-500/80"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Download for Mac &nbsp; ~ &nbsp; Intel
                          </a> */}
                        </div>{" "}
                      </motion.div>
                    </AnimatePresence>
                  ) : downloadOS === "Windows" ? (
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={"Windows"}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {" "}
                        <div className="flex flex-col items-stretch gap-4">
                          <a
                            href="https://dl.getautoflowstudio.com/windows"
                            className="relative inline-flex flex-shrink-0 text-secondary-color-light font-heading items-center justify-center rounded-xl border border-zinc-500/50 bg-zinc-500/10 px-3 py-2 text-sm transition hover:border-zinc-500/80"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download for Windows
                          </a>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  ) : downloadOS === "Linux" ? (
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={"Linux"}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {" "}
                        <div className="flex flex-col items-stretch gap-4">
                          <a
                            href="https://dl.getautoflowstudio.com/linux"
                            className="relative inline-flex flex-shrink-0 text-secondary-color-light font-heading items-center justify-center rounded-xl border border-zinc-500/50 bg-zinc-500/10 px-3 py-2 text-sm transition hover:border-zinc-500/80"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download for Linux &nbsp; ~ &nbsp;deb
                          </a>
                          {/* <a
                            href="/"
                            className="relative inline-flex flex-shrink-0 text-secondary-color-light font-heading items-center justify-center rounded-xl border border-zinc-500/50 bg-zinc-500/10 px-3 py-2 text-sm transition hover:border-zinc-500/80"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Download for Linux &nbsp; ~ &nbsp; AppImage
                          </a> */}
                        </div>{" "}
                      </motion.div>
                    </AnimatePresence>
                  ) : downloadOS === "Web" ? (
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={"Web"}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {" "}
                        <div className="flex flex-col items-stretch gap-4">
                          <a
                            href="/"
                            className="relative inline-flex flex-shrink-0 text-secondary-color-light font-heading items-center justify-center rounded-xl border border-zinc-500/50 bg-zinc-500/10 px-3 py-2 text-sm transition hover:border-zinc-500/80"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Open Web App
                          </a>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  ) : (
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={"CLI"}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {" "}
                        <div className="flex flex-col items-stretch gap-4">
                          <a
                            href="/"
                            className="relative inline-flex flex-shrink-0 text-secondary-color-light font-heading items-center justify-center rounded-xl border border-zinc-500/50 bg-zinc-500/10 px-3 py-2 text-sm transition hover:border-zinc-500/80"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Install CLI
                          </a>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AppBannerPage /> */}
    </div>
  );
};

export default DownloadsPage;
