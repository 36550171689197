import React, { useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";

const PasswordInput = ({ register, inputWidth, errors }) => {
  const [shownPassword, setShownPassword] = useState(false);

  return (
    <div className="flex flex-col justify-center -mb-2">
      <label className="form-control w-full max-w-xs">
        <div className="label -mb-1">
          <span className="label-text font-secondary text-white/70">
            Password
          </span>
        </div>
        <div
          className={`join ${inputWidth} border border-border-white/70 flex items-center`}
        >
          <input
            type={shownPassword ? "text" : "password"}
            placeholder="Enter your password"
            {...register}
            className={`input input-sm md:input-md bg-transparent border-none outline-none focus:border-white/70 text-white font-secondary w-full ${
              errors?.password && "border-red-500 focus:border-red-500"
            }`}
          />
          <span
            className="w-1/12 text-white text-center text-xl cursor-pointer"
            onClick={() => setShownPassword(!shownPassword)}
          >
            {shownPassword ? <IoEye /> : <IoEyeOff />}
          </span>
        </div>
        <div className="label">
          <span className="label-text-alt">
            {errors && <p className="text-red-500">{errors?.message}</p>}
          </span>
        </div>
      </label>
    </div>
  );
};

export default PasswordInput;
