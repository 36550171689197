import React from "react";
import { layout } from "../../styles/styles";
import { SectionHeader } from "../SectionHeader";
import { featuresData, sectionData } from "../../data";
import FeaturesCard from "../cards/FeaturesCard";

const Features = () => {
  let integratesData = featuresData[0].integrates;
  const supportsData = featuresData[0].supports;
  return (
    <section className={`${layout.section}`} id="features">
      <SectionHeader
        title={sectionData[0].features.title}
        desc={sectionData[0].features.desc}
      />
      <div className="flex flex-col sm:flex-row items-center justify-center pb-16 gap-4">
        <FeaturesCard
          title={integratesData.title}
          description={integratesData.description}
          img={integratesData.img}
          alt={integratesData.alt}
        />
        <FeaturesCard
          title={supportsData.title}
          description={supportsData.description}
          img={supportsData.img}
          alt={supportsData.alt}
        />
      </div>
    </section>
  );
};

export default Features;
