import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { thirdPartySignInAndUp } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { sdkLogin } from "../../routes/routes";
import LoadingPage from "./LoadingPage";

const GoogleAuth = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  async function handleGoogleCallback() {
    try {
      const response = await thirdPartySignInAndUp();
      if (response.status === "OK") {
        if (
          response.createdNewRecipeUser &&
          response.user.loginMethods.length === 1
        ) {
          // sign up successful
        } else {
          // sign in successful
        }
        window.location.assign("/account");
      } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // this can happen due to automatic account linking. Please see our account linking docs
      } else {
        // SuperTokens requires that the third party provider
        // gives an email for the user. If that's not the case, sign up / in
        // will fail.

        // As a hack to solve this, you can override the backend functions to create a fake email for the user.

        toast.error(
          "No email provided by social login. Please use another form of login"
        );
        window.location.assign(sdkLogin); // redirect back to login page
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else if (err.status === 409) {
        toast.error("Email already exists!");
        navigate(sdkLogin, {
          state: {
            message:
              "It appears that you've previously logged in using a different method",
            source: "third party",
          },
        });
      } else {
        navigate(sdkLogin, {
          state: {
            message: "Oops! Something went wrong.",
            source: "third party",
          },
        });
        toast.error("Oops! Something went wrong.");
      }
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  }
  useEffect(() => {
    handleGoogleCallback();
  }, []);
  if (loading) {
    // Render your loader here
    return <LoadingPage />;
  }

  return <></>;
};

export default GoogleAuth;
