import React from "react";
import { GoPeople } from "react-icons/go";
import { TbWavesElectricity } from "react-icons/tb";
import { LuCloudy } from "react-icons/lu";
import { PiStack } from "react-icons/pi";
import SparkleBackground from "../../../Animations/SparkleBackground";
import AppSectionHeader from "../../reUsableComponents/AppSectionHeader";
import {
  AppFeaturesCardData,
  app_features_featData,
} from "../../../../utils/Data";
import AppCard2 from "../../reUsableComponents/cards/AppCard2";
import AppCard3 from "../../reUsableComponents/cards/AppCard3";

const AppFeatures = () => {
  return (
    <section>
      <div className="max-w-5xl px-4 mx-auto sm:px-6">
        <div className="pt-32 pb-24 overflow-x-hidden">
          <SparkleBackground>
            <AppSectionHeader
              title="Features"
              mainTitle="Everything You Need For Your Flow through your workflows seamlessly"
              description="A comprehensive platform for seamless end-to-end testing."
            />
          </SparkleBackground>
          <div className="relative pb-12 md:pb-16">
            <div className="grid gap-6 md:grid-cols-12 group">
              {AppFeaturesCardData.firstRow.map((feature, index) => {
                return (
                  <div className="grid md:col-span-4" key={index}>
                    <AppCard2
                      icon={
                        <feature.icon className="text-lg text-secondary-color-light mr-2" />
                      }
                      title={feature.title}
                      description={feature.decription}
                      content={feature.content}
                      contentPadding={feature.contentPadding}
                      contentHeight={feature.contentHeight}
                    />
                  </div>
                );
              })}

              <div className="grid md:col-span-4">
                <AppCard2
                  icon={
                    <TbWavesElectricity className="text-lg text-secondary-color-light mr-2" />
                  }
                  title={AppFeaturesCardData.secondRow[0].title}
                  description={AppFeaturesCardData.secondRow[0].decription}
                  content={AppFeaturesCardData.secondRow[0].content}
                  contentPadding={
                    AppFeaturesCardData.secondRow[0].contentPadding
                  }
                  contentHeight={AppFeaturesCardData.secondRow[0].contentHeight}
                />
              </div>
              <div className="grid md:col-span-8">
                <AppCard2
                  icon={
                    <LuCloudy className="text-lg text-secondary-color-light mr-2" />
                  }
                  title={AppFeaturesCardData.secondRow[1].title}
                  description={AppFeaturesCardData.secondRow[1].decription}
                  content={AppFeaturesCardData.secondRow[1].content}
                  contentPadding={
                    AppFeaturesCardData.secondRow[1].contentPadding
                  }
                  contentHeight={AppFeaturesCardData.secondRow[1].contentHeight}
                />
              </div>
              <div className="grid md:col-span-8">
                <AppCard2
                  icon={
                    <PiStack className="text-lg text-secondary-color-light mr-2" />
                  }
                  title={AppFeaturesCardData.thirdRow[0].title}
                  description={AppFeaturesCardData.thirdRow[0].decription}
                  content={AppFeaturesCardData.thirdRow[0].content}
                  contentPadding={
                    AppFeaturesCardData.thirdRow[0].contentPadding
                  }
                  contentHeight={AppFeaturesCardData.thirdRow[0].contentHeight}
                />
              </div>
              <div className="grid md:col-span-4">
                <AppCard2
                  icon={
                    <GoPeople className="text-lg text-secondary-color-light mr-2" />
                  }
                  title={AppFeaturesCardData.thirdRow[1].title}
                  description={AppFeaturesCardData.thirdRow[1].decription}
                  content={AppFeaturesCardData.thirdRow[1].content}
                  contentPadding={
                    AppFeaturesCardData.thirdRow[1].contentPadding
                  }
                  contentHeight={AppFeaturesCardData.thirdRow[1].contentHeight}
                />
              </div>
            </div>
          </div>
          <div className="grid gap-8 sm:grid-cols-3 md:gap-12">
            {app_features_featData.map((feature, index) => (
              <div className="pl-4" key={index}>
                <AppCard3
                  icon={<feature.icon className="text-white" />}
                  title={feature.title}
                  description={feature.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppFeatures;
