export const container = "relative max-w-5xl px-4 mx-auto sm:px-6 overflow-hidden";
export const flexContainer = "flex flex-col-reverse max-w-xl mx-auto space-y-8 space-y-reverse md:max-w-none md:flex-row md:space-x-8 lg:space-x-16 xl:space-x-20 md:space-y-0";
export const textContainer = "order-1 md:w-7/12 lg:w-1/2 max-md:text-center md:order-none";
export const gradientSpan = "mb-6 inline-flex max-w-max bg-gradient-to-br from-zinc-50 to-zinc-600 bg-clip-text text-transparent";
export const mainHeading = "block max-w-max text-2xl sm:text-4xl bg-gradient-to-r from-white via-white/80 to-white/30 bg-clip-text pb-3 text-transparent";
export const buttonContainer = "mt-8 max-w-xs space-y-2 max-md:mx-auto";
export const button = "flex w-full items-center gap-x-3 rounded-md border bg-zinc-900/25 px-3 py-2 text-sm text-secondary-color-light/50  transition";
export const waveLayer1 = "absolute inset-0 [background:_repeating-linear-gradient(transparent,_transparent_48px,_theme(colors.white)_48px,_theme(colors.white)_49px)] blur-[2px] opacity-20";
export const waveLayer2 = "absolute inset-0 [background:_repeating-linear-gradient(transparent,_transparent_48px,_theme(colors.zinc.500)_48px,_theme(colors.zinc.500)_49px)]";
export const waveLayer3 = "absolute inset-0 [background:_repeating-linear-gradient(90deg,_transparent,_transparent_48px,_theme(colors.white)_48px,_theme(colors.white)_49px)] blur-[2px] opacity-20";
export const waveLayer4 = "absolute inset-0 [background:_repeating-linear-gradient(90deg,_transparent,_transparent_48px,_theme(colors.zinc.500)_48px,_theme(colors.zinc.500)_49px)]";
export const iconCircle = "relative flex items-center justify-center w-16 h-16 border border-transparent rounded-2xl shadow-2xl -rotate-[14deg] [background:linear-gradient(theme(colors.zinc.900),_theme(colors.zinc.900))_padding-box,_conic-gradient(theme(colors.zinc.400),_theme(colors.zinc.700)_25%,_theme(colors.zinc.700)_75%,_theme(colors.zinc.400)_100%)_border-box] before:absolute before:inset-0 before:bg-zinc-900/30 before:rounded-2xl";
export const icon = "text-white text-2xl";
