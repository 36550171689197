import React from "react";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import SparkleBackground from "../../../Animations/SparkleBackground";
import AppSectionHeader from "../../reUsableComponents/AppSectionHeader";
import AppCard1 from "../../reUsableComponents/cards/AppCard1";

const AppOpenSource = () => {
  return (
    <section>
      <div className="max-w-5xl px-4 mx-auto sm:px-6">
        <div className="pt-32 pb-24">
          <SparkleBackground>
            <AppSectionHeader
              title="Open Source"
              mainTitle="Fully Customizable"
              description="Get granular control over your tests with AutoFlow Studio."
            />
          </SparkleBackground>

          <div className="max-w-4xl mx-auto">
            <div
              className={`relative w-full h-full bg-gradient-to-b from-primary-color-light/10 to-primary-color-light/5 rounded-3xl p-px  overflow-hidden`}
            >
              <AppCard1
                icon={<FaRegHeart className="text-2xl text-white" />}
                title={"Made with Love"}
                description={"We have one of the most popular UI testing SDKs on GitHub."}
                descWidth="w-full"
                content={
                  <>
                    <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,theme(colors.zinc.400)1A_1px,transparent_1px),linear-gradient(to_bottom,theme(colors.zinc.400)1A_1px,transparent_1px)] bg-[size:129px_128px] bg-center"></div>
                    <div className="flex flex-col items-center justify-center">
                      <div className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black-calc(100%-128px),transparent_100%)]">
                        {[...Array(2)].map((_, i) => (
                          <ul
                            key={i}
                            className="flex animate-infinite-scroll items-center justify-center"
                          >
                            {[...Array(12)].map((_, j) => (
                              <Link
                                key={j}
                                to={"https://github.com/AutoFlowLabs/autoflow"}
                                target="_blank"
                              >
                                <li
                                  className="mx-8 table items-center truncate text-center text-6xl font-semibold leading-normal tracking-tight text-secondary-color-light/60 "
                                >
                                  Star us on GitHub
                                </li>
                              </Link>
                            ))}
                          </ul>
                        ))}
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppOpenSource;
