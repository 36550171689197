import React from "react";
import { FaDollarSign } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { sdkBilling, sdkLogin } from "../../../../../routes/routes";

const AutoFlowBasicCard = ({ sessionExists }) => {
  const navigate = useNavigate();

  const openCheckout = () => {
    sessionExists ? navigate(sdkBilling) : navigate(sdkLogin);
  };
  return (
    <div className="card border border-gray-600 w-[360px] md:w-96 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-primary-color text-xl sm:text-2xl font-bold">
          AutoFlow Basic
        </h2>
        <p className="font-secondary font-light text-sm sm:text-base text-white/55">
        for Business and start ups
        </p>
        <p className="sm:text-2xl font-bold mb-2 mt-5 flex flex-col">
          <div className="flex flex-row items-center text-white">
            <FaDollarSign />
            <span className="text-4xl sm:text-6xl join">50</span>
          </div>
          <div className="text-sm sm:text-base px-2 text-white">/ month</div>
        </p>

        <div className="card-actions py-2 justify-start">
          <button
            onClick={openCheckout}
            className="btn btn-sm w-40 btn-outline btn-warning sm:btn-md font-secondary"
          >
            Buy now
          </button>
        </div>
      </div>
      <div className="bg-gray-800 h-32 p-4 px-8 rounded-b-2xl">
        <h3 className="text-white font-primary font-bold mb-1">Features:</h3>

        <p className="mb-1 text-sm sm:text-base font-semibold text-white">
          - 5,000{" "}
          <span className="text-primary-color font-semibold">autoflow()</span>{" "}
          invocations per month
        </p>
        <p className="mb-1 text-sm sm:text-base font-semibold text-white">
          - 5 users
        </p>
      </div>
    </div>
  );
};

export default AutoFlowBasicCard;
