import React from "react";
import { FaDollarSign } from "react-icons/fa";
import { Link } from "react-router-dom";
import { sdkSignUp } from "../../../../../routes/routes";

const PriceCard = ({ title, price, tenure, features, description }) => {
  return (
    <div className="card border border-gray-600 w-[360px] md:w-96 shadow-xl">
      <div className="card-body">
        <h2 className="card-title text-primary-color text-xl sm:text-2xl font-bold">
          {title}
        </h2>
        <p className="font-secondary font-light text-sm sm:text-base text-white/55">{description}</p>
        <p className="text-xl sm:text-2xl font-bold mb-2 mt-5 flex flex-col">
          <div className="flex flex-row items-center text-white">
            <FaDollarSign />
            <span className="text-4xl sm:text-6xl join">{price}</span>
          </div>
          <div className="text-sm sm:text-base px-2 text-white">/ {tenure}</div>
        </p>

        <div className="card-actions py-2 justify-start">
          <Link to={sdkSignUp}>
            <button className="btn btn-sm w-40 btn-outline btn-warning sm:btn-md font-secondary">
              Try For Free
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-gray-800 h-32 p-4 px-8 rounded-b-2xl">
        <h3 className="text-white font-primary font-bold mb-1">Features:</h3>
        {Array.isArray(features) &&
          features.map((feature, index) => (
            <p
              key={index}
              className="mb-1 text-sm sm:text-base font-semibold text-white"
            >
              - {feature}
            </p>
          ))}
      </div>
    </div>
  );
};

export default PriceCard;
