import React, { useEffect, useState } from "react";
import { FaDiscord, FaGithub } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { logo } from "../../assets";
import { sdkAccount, sdkLogin } from "../../../../../routes/routes";
import Session from "supertokens-web-js/recipe/session";


const MobileNav = ({ openMenu, setOpenMenu, sessionExists }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [doesSessionExistState, setDoesSessionExistState] = useState(false);


  const handleScroll = () => {
    // Check which section is currently in view
    const sections = ["land", "examples", "features", "pricing"];
    const offset = window.innerHeight / 2;

    for (const section of sections) {
      const element = document.getElementById(section);
      if (
        element.offsetTop - offset <= window.scrollY &&
        element.offsetTop + element.offsetHeight - offset > window.scrollY
      ) {
        setActiveSection(section);
        break;
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (await Session.doesSessionExist()) {
        setDoesSessionExistState(true);
      }
    })();
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return openMenu ? (
    <div className="absolute top-0 right-0 w-screen h-screen bg-gray-800 flex flex-col justify-between bg-opacity-100 font-primary font-bold">
      <div>
        <div className="pt-6">
          <div className="flex flex-row items-center justify-between">
            <div className="-my-14 relative cursor-pointer">
              <img src={logo} alt="Logo" className="h-40 sm:h-52" />
              <div class="badge absolute bg-primary-color bottom-[48px] right-0">SDK</div>
            </div>
            <button
              className="btn btn-ghost"
              onClick={() => setOpenMenu(false)}
            >
              <IoCloseCircleSharp className="text-4xl text-white" />
            </button>
          </div>
        </div>
        <div className="flex flex-col items-end pt-10 space-y-1 ">
          <Link
            to="land"
            smooth={true}
            duration={500}
            onClick={() => setOpenMenu(false)}
          >
            <button
              className={`btn btn-ghost text-2xl  ${
                activeSection === "land" ? " text-yellow-600" : "text-white"
              }`}
            >
              Home
            </button>
          </Link>
          <Link
            to="examples"
            smooth={true}
            duration={500}
            onClick={() => setOpenMenu(false)}
          >
            <button
              className={`btn btn-ghost text-2xl  ${
                activeSection === "examples" ? " text-yellow-600" : "text-white"
              }`}
            >
              Examples
            </button>
          </Link>
          <Link
            to="features"
            smooth={true}
            duration={500}
            onClick={() => setOpenMenu(false)}
          >
            <button
              className={`btn btn-ghost text-2xl  ${
                activeSection === "features" ? " text-yellow-600" : "text-white"
              }`}
            >
              Features
            </button>
          </Link>
          <Link
            to="pricing"
            smooth={true}
            duration={500}
            onClick={() => setOpenMenu(false)}
          >
            <button
              className={`btn btn-ghost text-2xl  ${
                activeSection === "pricing" ? " text-yellow-600" : "text-white"
              }`}
            >
              Pricing
            </button>
          </Link>

          <a
            href="https://github.com/AutoFlowLabs/autoflow"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`btn btn-ghost text-2xl text-white`}>
              Docs
            </button>
          </a>
        </div>
      </div>
      <div className="flex flex-col items-center gap-2 p-4 mb-24 w-full">
        <a
          href="https://discord.gg/FHXJRkYqEQ"
          target="_blank"
          className="w-full"
          rel="noreferrer"
        >
          <button className="btn btn-outline text-white w-full">
            <FaDiscord />
            Discord
          </button>
        </a>
        <a
          href="https://github.com/AutoFlowLabs/autoflow"
          target="_blank"
          className="w-full"
          rel="noreferrer"
        >
          <button className="btn btn-outline text-white w-full">
            <FaGithub />
            Github
          </button>
        </a>
        {sessionExists ? (
          <NavLink to={sdkAccount} className={"w-full"}>
            <button className="btn btn-warning w-full text-white">
              Dashboard
            </button>
          </NavLink>
        ) : (
          <NavLink to={sdkLogin} className={"w-full"}>
            <button className="btn btn-warning w-full text-white">
            {doesSessionExistState ? "Dashboard" : "Sign In"}
            </button>
          </NavLink>
        )}
      </div>
    </div>
  ) : null;
};

export default MobileNav;
