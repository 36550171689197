import React from "react";
import "./metors.css";

const Metors = () => {
  return (
    <div className="relative">
      <div className="meteor-1"></div>
      <div className="meteor-2"></div>
      <div className="meteor-3"></div>
      <div className="meteor-4"></div>
    </div>
  );
};

export default Metors;
