import React, { useEffect } from 'react'
import AppSetupTest from "../AppSetupTest";
import AppPotential from '../AppPotential';
import AppFeatures from '../AppFeatures';

function FeaturesPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <AppFeatures />
        <AppSetupTest/>
        <AppPotential/>
        </>
    );
}

export default FeaturesPage;