export const clientsData = [
  {
    id: 1,
    name: "For QA Teams",
    title: "Flow into faster testing.",
    sub1: "Accelerate test cycles without compromising quality",
    sub2: "Swap slow and sluggish tools for Autoflow's speed.",
    icon: "🚀",
    link: "/for-QA"
  },
  {
    id: 2,
    name: "For Developers",
    title: "Build More, Test Less/ Dev Faster, Test Smarter",
    sub1: "Shift Left. Build Right",
    sub2: "Code or no-code: Your testing style, our automation",
    icon: "💻",
    link: "/for-Dev"
  },
  {
    id: 3,
    name: "For Product Managers",
    title: "Your Round-The-Clock QA ",
    sub1: " Catch bugs before the users ",
    sub2: "Accelerate time-to-market with rapid testing",
    icon: "👏",
    link: "/for-Product-manager"
  },
];