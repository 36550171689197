import React from "react";

const AppCard3 = ({ icon, title, description }) => {
  return (
    <>
      <div className="relative flex items-center mb-1 space-x-2 before:-ml-4 before:absolute before:inset-y-0 before:w-px before:bg-secondary-color-light/20 font-display">
        {icon}
        <span className="text-secondary-color-light/90">{title}</span>
      </div>
      <p className="text-sm text-secondary-color-light/50 font-display">{description}</p>
    </>
  );
};

export default AppCard3;
