import React, { useEffect, useState } from "react";
import { loadingAnimation } from "../../assets";

const LoadingPage = () => {
  const [loopingText, setLoopingText] = useState(
    "Preparing everything for you. Just a moment longer! "
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newLoopingText = loopingText.slice(1) + loopingText.slice(0, 1);
      setLoopingText(newLoopingText);
    }, 500);

    let count = 0;
    const maxCount = 3;
    const loopTimer = setInterval(() => {
      count++;
      if (count === maxCount) {
        clearInterval(interval);
        setTimeout(() => {
          setLoopingText(" ");
        }, 2000);
      }
    }, 500);

    return () => {
      clearInterval(interval);
      clearInterval(loopTimer);
    };
  }, [loopingText]);
  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen bg-black text-white overflow-auto">
      <div>
        <video
          autoPlay
          muted
          loop
          playsInline
          className="w-[900px] text-center"
        >
          <source src={loadingAnimation} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <p
        className="text-center font-primary mt-2 text-white/60 text-sm sm:text-2xl  md:text-4xl"
        style={{
          background: "linear-gradient(90deg, #F8F8F9, #f48338)",
          WebkitBackgroundClip: "text",
          color: "transparent",
          textShadow: "-2px 3px 4px rgba(0, 0, 0, 0.5)",
          animation: "colorChange1 5s infinite alternate",
        }}
      >
        {loopingText}
      </p>
    </div>
  );
};

export default LoadingPage;
