import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // For extracting query parameters
import axios from 'axios'; // For making API calls
import JourneyRunReport from '../../../components/AppLandingPageComponents/landingPageComponents/JourneyRunReport'; 

const ReportPage = () => {
  const [searchParams] = useSearchParams(); // Extract query params from URL
  const journeyRunId = searchParams.get('journey_run_id');
  const orgId = searchParams.get('org_id');

  const [reportData, setReportData] = useState(null); // Holds the report data
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track errors

  useEffect(() => {
    // Function to fetch report data from API
    const fetchReport = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_STUDIO_BASE_URL}/api/v1/report`, {
          params: { journey_run_id: journeyRunId, organisation_id: orgId },
        });
        setReportData(response.data); // Update report data
        setLoading(false); // Mark as not loading
      } catch (error) {
        setError(error); // Store error details
        setLoading(false); // Mark as not loading
      }
    };

    // Fetch data only if both journeyRunId and orgId are available
    if (journeyRunId && orgId) {
      fetchReport();
    }
  }, [journeyRunId, orgId]); // Trigger useEffect when journeyRunId or orgId changes

  // Conditional rendering based on the state
   if (loading) {
    return (
      <div className="h-screen flex flex-col items-center justify-center bg-surface-primary">
        <span className="loading loading-spinner loading-lg text-primary-color"></span>
      </div>
    );
  }
  if (error) return <div>Error fetching report: {error.message}</div>;
  // Render the fetched report data with proper UI
  return (
    <div className='pt-20 md:pt-24 '>
      {/* Render JourneyRunReport UI with the reportData */}
      {reportData ? (
        // console.log(`Report Data:`, reportData),
        reportData.organisationId = orgId,
        <JourneyRunReport report={reportData} orgId={orgId} /> 
      ) : (
        <div>No report data available.</div>
      )}
    </div>
  );
};

export default ReportPage;