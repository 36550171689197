import React from 'react';
import { Link } from 'react-router-dom';

const PlanCard = ({
  title,
  description,
  price,
  tenure,
  invocationsUsed,
  totalInvocations,
  handleClick,
  billing_cycle,
  next_billing_at,
  update_payment_url,
  cancel_subscription_url,
  trigger
}) => {
  // Calculate the percentage of usage

  const usagePercentage = totalInvocations > invocationsUsed 
  ? (invocationsUsed / totalInvocations) * 100 
  : 100;


  // Determine the color of the progress bar based on the usage percentage
  let progressBarColor;
  if (usagePercentage < 20) {
    progressBarColor = 'bg-green-500';
  } else if (usagePercentage >= 20 && usagePercentage <= 80) {
    progressBarColor = 'bg-yellow-500';
  } else {
    progressBarColor = 'bg-red-500';
  }

  return (
    <div className="bg-black border border-gray-800 shadow-sm shadow-slate-700 sm:w-[500px] rounded-lg p-4 text-white">
      <div className="flex flex-col md:flex-row items-start justify-between mb-4">
        <div>
          <h1 className="text-lg md:text-2xl font-primary font-bold text-primary-color">
            {title}
          </h1>
          <p className="text-[10px] md:text-sm text-white/80 mt-1">
            {description}
          </p>
        </div>
        <div className="my-4 md:mt-0">
          <p className="flex flex-col md:flex-row md:items-end gap-x-1">
            <span className="text-xl font-primary font-bold">{price}</span>{" "}
            <span className="font-secondary text-white/70">/{tenure}</span>
          </p>
        </div>
      </div>
      <div className="">
        <div className="flex flex-col items-start">
          <div className="font-primary font-bold text-sm md:text-base">
            {invocationsUsed} / {totalInvocations} {trigger}
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-2">
            <div
              className={`${progressBarColor} h-2.5 rounded-full`}
              style={{ width: `${usagePercentage}%` }}
            ></div>
          </div>
        </div>
        <div className="mt-4">
          {next_billing_at ? (
            <p className="text-[10px] md:text-sm text-white/80">
              {billing_cycle && <span>Billing cycle: {billing_cycle} | </span>}
              Next billing at {next_billing_at}
            </p>
          ) : (
            <p className="text-[10px] md:text-sm text-white/80">
              No upcoming billing
            </p>
          )}
        </div>
        <div className="mt-6 text-end">
          {!cancel_subscription_url ? (
            <a
              onClick={handleClick}
              className="btn btn-sm btn-warning font-secondary"
            >
              Upgrade
            </a>
          ) : (
            <>
              {update_payment_url && (
                <Link
                  target="_blank"
                  to={update_payment_url}
                  className="btn btn-sm btn-warning font-secondary mr-2"
                >
                  Update Subscription
                </Link>
              )}
              <Link
                target="_blank"
                to={cancel_subscription_url}
                className="btn btn-sm btn-error font-secondary"
              >
                Cancel Subscription
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCard;