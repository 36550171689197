import React from "react";
import * as styles from "./AppClientStyles";
import { clientsData } from "./data";
const AppClients = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <p className={styles.paragraph}>
          Join the developers, QAs and Product managers <br /> Trusted by developers, QA, and product teams at startups and enterprises..
        </p>
        <div className={styles.scrollWrapper}>
          <ul className={styles.scrollingList}>
            {clientsData.map((data) => {
              return (
                <img
                  className={styles.listItem}
                  loading="lazy"
                  src={data.icon}
                  alt={data.name}
                  key={data.id}
                />
              );
            })}
          </ul>
          <ul className={styles.scrollingList}>
            {clientsData.map((data) => {
              return (
                <img
                  className={styles.listItem}
                  loading="lazy"
                  src={data.icon}
                  alt={data.name}
                  key={data.id}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AppClients;
