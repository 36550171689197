import React, { useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar } from "react-chartjs-2";

function BarChart({ days, invocations }) {
  defaults.maintainAspectRatio = false;
  defaults.responsive = true;
  const [delayed, setDelayed] = useState(false);

  const options = {
    animation: {
      onComplete: () => {
        setDelayed(true);
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && !delayed) {
          delay = context.dataIndex * 100 + context.datasetIndex * 50;
        }
        return delay;
      },
    },
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: "#878787", // Change the color of x-axis labels here
        },
        grid: {
          color: "#878787",

          // Change the color of x-axis gridlines here
        },
      },
      y: {
        ticks: {
          color: "#878787", // Change the color of y-axis labels here
        },
        grid: {
          color: "#878787", // Change the color of y-axis gridlines here
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "#878787",
          boxWidth: 40,
          boxHeight: 10,
          // Change the color of the legend text to red
        },
      },
    },
  };
  return (
    <div>
      <Bar
        data={{
          labels: days,
          datasets: [
            {
              label: "Invocations",
              data: invocations,
              backgroundColor: ["#F4AC38"],
              borderColor: ["#F4AC38"],

              borderRadius: 3,
            },
          ],
        }}
        options={options}
      />
    </div>
  );
}

export default BarChart;
