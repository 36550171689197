import React from "react";
import "./SparkleBackground.css";

const SparkleBackground = ({ children, className }) => {
  return (
    <div className={`sparkle-container ${className}`}>
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className={`star ${index % 2 === 0 ? "blink" : ""}`}
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 5}s`,
          }}
        ></div>
      ))}
      {children}
    </div>
  );
};

export default SparkleBackground;
